import React from 'react';
import newcollectionhaver1 from '../assets/img/uploads/2022/12/h2-prod-list-haver-1.jpg';
import { NavLink } from 'react-router-dom';
import FormatPrice from "../Helpers/FormatPrice";

const Product = (curElem) => {
 const { id, name, image, price, category } = curElem;
  return (
	<li className="qodef-e qodef-grid-item qodef-item--full product type-product post-1985 status-publish last instock product_cat-elegant product_cat-new-sets product_tag-gold product_tag-jewerly has-post-thumbnail shipping-taxable purchasable product-type-variable">
		<div className="qodef-e-inner">
			<div className="qodef-e-media">
				<div className="qodef-e-media-image">
					<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"> 
						<img width="800" height="913" src={image} className="attachment-full size-full" alt={name} decoding="async" loading="lazy" srcSet={image} sizes="(max-width: 800px) 100vw, 800px" /> 
					</a> 
				</div>
				<div className="qodef-e-media-image-hover">
					<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"> 
					<img width="800" height="913" src={newcollectionhaver1} className="attachment-full size-full" alt="d" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-prod-list-haver-1.jpg 800w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-prod-list-haver-1-480x548.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-prod-list-haver-1-263x300.jpg 263w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-prod-list-haver-1-768x876.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-prod-list-haver-1-400x457.jpg 400w" sizes="(max-width: 800px) 100vw, 800px" /></a> 
				</div>
				<div className="qodef-e-media-inner">

					<NavLink to={`/${id}`} data-quantity="1" className="button wp-element-button product_type_variable add_to_cart_button" data-product_id="1985" data-product_sku="081" aria-label="Select options for &ldquo;Necklaces Sets&rdquo;" rel="nofollow">Select options</NavLink>
				</div>
				{/* <a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"></a>  */}
			</div>
			
			<div className="qodef-e-content">
					<div className="qodef-e-top-holder">
						<h6 itemProp="name" className="qodef-woo-product-title entry-title">
							<NavLink to={`/${id}`} itemProp="url" className="qodef-woo-product-title-link" href="#">{name}</NavLink>
						</h6>
						<div className="qodef-additional-holder">
							<div className="yith-wcwl-add-to-wishlist add-to-wishlist-1985  wishlist-fragment on-first-load" data-fragment-ref="1985" data-fragment-options="{&quot;base_url&quot;:&quot;&quot;,&quot;in_default_wishlist&quot;:false,&quot;is_single&quot;:false,&quot;show_exists&quot;:false,&quot;product_id&quot;:1985,&quot;parent_product_id&quot;:1985,&quot;product_type&quot;:&quot;variable&quot;,&quot;show_view&quot;:false,&quot;browse_wishlist_text&quot;:&quot;Browse wishlist&quot;,&quot;already_in_wishslist_text&quot;:&quot;The product is already in your wishlist!&quot;,&quot;product_added_text&quot;:&quot;Product added!&quot;,&quot;heading_icon&quot;:&quot;fa-heart-o&quot;,&quot;available_multi_wishlist&quot;:false,&quot;disable_wishlist&quot;:false,&quot;show_count&quot;:false,&quot;ajax_loading&quot;:false,&quot;loop_position&quot;:&quot;after_add_to_cart&quot;,&quot;item&quot;:&quot;add_to_wishlist&quot;}">
								<div className="yith-wcwl-add-button">
									<a href="indexe174.html?add_to_wishlist=1985&amp;_wpnonce=b57af069ef" className="add_to_wishlist single_add_to_wishlist" data-product-id="1985" data-product-type="variable" data-original-product-id="1985" data-title="Add to wishlist" rel="nofollow">
									<i className="yith-wcwl-icon fa fa-heart-o"></i> <span>Add to wishlist</span>
									</a>
								</div>
							</div>
							<a href="#" className="button yith-wcqv-button" data-product_id="1985">Quick View</a>  
						</div>
					</div>										
					<div className="qodef-e-info">
						<NavLink to={`/${id}`} href="#" rel="tag">{category}</NavLink>
						{/* <span className="qodef-info-separator-single"></span><a href="#" rel="tag">New Sets</a>  */}
						<div className="qodef-info-separator-end"></div>
					</div>
					<div className="qodef-woo-product-price price">
						<span className="woocs_price_code" data-currency="" data-redraw-id="647831b68f6f8" data-product-id="1985">
						<span className="woocommerce-Price-amount amount">{<FormatPrice price={price} />}</span></span>
					</div>
			</div>
		</div>
	</li>
  )
};

export default Product;