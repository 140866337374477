import React from 'react';
import shoplist1 from '../assets/img/uploads/2022/12/h2-shop-lista-image-n-3.jpg';
import shoplist2 from '../assets/img/uploads/2022/12/h2-shop-lista-image-n-2.jpg';
import shoplist3 from '../assets/img/uploads/2022/12/h2-shop-lis-i-2.jpg';
import shoplist4 from '../assets/img/uploads/2022/12/h2-shop-lis-i-4.jpg';
import shoplist5 from '../assets/img/uploads/2022/12/h2-shop-lista-image-n-1.jpg';

const Collection = () => {
  return (
    <div>
      	<section className="elementor-section elementor-top-section elementor-element elementor-element-387ad19 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="387ad19" data-element_type="section">
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-69dfff7" data-id="69dfff7" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-9a8660c elementor-widget elementor-widget-text-editor" data-id="9a8660c" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<h3>new collection</h3> 
							</div>
						</div>
						<div className="elementor-element elementor-element-4013584 elementor-widget elementor-widget-ascella_core_product_list" data-id="4013584" data-element_type="widget" data-widget_type="ascella_core_product_list.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-woo-shortcode qodef-woo-product-list qodef-item-layout--price-switch  qodef-category-filter-type--icon   qodef-grid qodef-swiper-container    qodef-col-num--3 qodef-item-layout--price-switch qodef-pagination--off qodef-responsive--custom qodef-col-num--1440--3 qodef-col-num--1366--3 qodef-col-num--1024--3 qodef-col-num--768--2 qodef-col-num--680--1 qodef-col-num--480--1" data-options="{&quot;slidesPerView&quot;:&quot;3&quot;,&quot;spaceBetween&quot;:0,&quot;loop&quot;:true,&quot;autoPlay&quot;:true,&quot;speed&quot;:&quot;&quot;,&quot;speedAnimation&quot;:&quot;&quot;,&quot;slideAnimation&quot;:&quot;&quot;,&quot;slideDecimal&quot;:&quot;1.45&quot;,&quot;direction&quot;:&quot;&quot;,&quot;centeredSlides&quot;:true,&quot;sliderScroll&quot;:&quot;&quot;,&quot;loopAdditionalSlides&quot;:&quot;&quot;,&quot;customStages&quot;:true,&quot;slidesPerView1440&quot;:&quot;3&quot;,&quot;slidesPerView1366&quot;:&quot;3&quot;,&quot;slidesPerView1024&quot;:&quot;3&quot;,&quot;slidesPerView768&quot;:&quot;2&quot;,&quot;slidesPerView680&quot;:&quot;1&quot;,&quot;slidesPerView480&quot;:&quot;1&quot;}">
									<ul className="swiper-wrapper">
										<li className="qodef-e swiper-slide qodef-item--full product type-product post-2693 status-publish first instock product_cat-golden-line product_tag-gold product_tag-jewerly has-post-thumbnail shipping-taxable purchasable product-type-simple">
											<div className="qodef-e-inner">
												<div className="qodef-e-media">
													<div className="qodef-e-media-image">
														<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link">
															<img width="400" height="388" src={shoplist1} className="attachment-full size-full" alt="g" decoding="async" loading="lazy" srcSet={shoplist1} sizes="(max-width: 400px) 100vw, 400px" /> 
														</a>
													</div>
													<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"></a>
												</div>
												<div className="qodef-e-content">
													<div className="qodef-e-top-holder">
														<h6 itemProp="name" className="qodef-woo-product-title entry-title">
															<a itemProp="url" className="qodef-woo-product-title-link" href="#">Wedding Ring	</a>
														</h6>
													</div>
													<div className="qodef-e-info">
														<a href="#" rel="tag">Golden Line</a> <div className="qodef-info-separator-end"></div>
													</div>
													<div className="qodef-additional-holder">
														<div className="qodef-woo-product-price price">
															<span className="woocs_price_code" data-currency="" data-redraw-id="647831b678743" data-product-id="2693">
															<span className="woocommerce-Price-amount amount">102.00<span className="woocommerce-Price-currencySymbol">&#036;</span></span>
															</span>
														</div>
														<a href="#" data-quantity="1" className="button wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id="2693" data-product_sku="120" aria-label="Add &ldquo;Wedding Ring&rdquo; to your cart" rel="nofollow">Add to cart</a> 
													</div>
													<div className="qodef-e-bottom"></div>
												</div>
											</div>
										</li>
										<li className="qodef-e swiper-slide qodef-item--full product type-product post-2705 status-publish instock product_cat-golden-line product_tag-gold product_tag-jewerly has-post-thumbnail shipping-taxable purchasable product-type-simple">
											<div className="qodef-e-inner">
												<div className="qodef-e-media">
													<div className="qodef-e-media-image">
														<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"> 
														<img width="400" height="388" src={shoplist2} className="attachment-full size-full" alt="g" decoding="async" loading="lazy" srcSet={shoplist2} sizes="(max-width: 400px) 100vw, 400px" /> </a> 
													</div>
														<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"></a> 
												</div>
												<div className="qodef-e-content">
													<div className="qodef-e-top-holder">
														<h6 itemProp="name" className="qodef-woo-product-title entry-title">
														<a itemProp="url" className="qodef-woo-product-title-link" href="#">Earrings Plate	</a>
														</h6>
													</div>
													<div className="qodef-e-info">
														<a href="#" rel="tag">Golden Line</a> 
														<div className="qodef-info-separator-end"></div>
													</div>
													<div className="qodef-additional-holder">
														<div className="qodef-woo-product-price price"><span className="woocs_price_code" data-currency="" data-redraw-id="647831b679bfc" data-product-id="2705"><span className="woocommerce-Price-amount amount">68.00<span className="woocommerce-Price-currencySymbol">&#036;</span></span></span></div>
															<a href="#" data-quantity="1" className="button wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id="2705" data-product_sku="112" aria-label="Add &ldquo;Earrings Plate&rdquo; to your cart" rel="nofollow">Add to cart</a> 
														</div>
													<div className="qodef-e-bottom"></div>
												</div>
											</div>
										</li>
										<li className="qodef-e swiper-slide qodef-item--full product type-product post-2708 status-publish last instock product_cat-golden-line product_cat-hoops product_tag-gold product_tag-jewerly has-post-thumbnail shipping-taxable purchasable product-type-simple">
											<div className="qodef-e-inner">
												<div className="qodef-e-media">
													<div className="qodef-e-media-image">
														<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"> 
															<img width="800" height="775" src={shoplist3} className="attachment-full size-full" alt="d" decoding="async" loading="lazy" srcSet={shoplist3} sizes="(max-width: 800px) 100vw, 800px" /> </a> 
													</div>
													<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"></a> 
												</div>
												<div className="qodef-e-content">
													<div className="qodef-e-top-holder">
														<h6 itemProp="name" className="qodef-woo-product-title entry-title">
															<a itemProp="url" className="qodef-woo-product-title-link" href="#">
														Heart Hoops	</a>
														</h6>
													</div>
													<div className="qodef-e-info">
														<a href="#" rel="tag">Golden Line</a><span className="qodef-info-separator-single"></span><a href="#" rel="tag">Hoops</a> <div className="qodef-info-separator-end"></div>
													</div>
													<div className="qodef-additional-holder">
														<div className="qodef-woo-product-price price"><span className="woocs_price_code" data-currency="" data-redraw-id="647831b67b0e3" data-product-id="2708"><span className="woocommerce-Price-amount amount">68.00<span className="woocommerce-Price-currencySymbol">&#036;</span></span></span></div>
														<a href="#" data-quantity="1" className="button wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id="2708" data-product_sku="113" aria-label="Add &ldquo;Heart Hoops&rdquo; to your cart" rel="nofollow">Add to cart</a>
													</div>
													<div className="qodef-e-bottom"></div>
												</div>
											</div>
										</li>
										<li className="qodef-e swiper-slide qodef-item--full product type-product post-2717 status-publish first instock product_cat-golden-line product_cat-hoops product_tag-gold product_tag-jewerly has-post-thumbnail shipping-taxable purchasable product-type-simple">
											<div className="qodef-e-inner">
												<div className="qodef-e-media">
													<div className="qodef-e-media-image">
													<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"> 
													<img width="800" height="775" src={shoplist4} className="attachment-full size-full" alt="f" decoding="async" loading="lazy" srcSet={shoplist4} sizes="(max-width: 800px) 100vw, 800px" /> </a> </div>
													<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"></a> 
												</div>
												<div className="qodef-e-content">
													<div className="qodef-e-top-holder">
														<h6 itemProp="name" className="qodef-woo-product-title entry-title">
														<a itemProp="url" className="qodef-woo-product-title-link" href="#">
														Necklaces Hoop	</a>
														</h6>
													</div>
													<div className="qodef-e-info">
														<a href="#" rel="tag">Golden Line</a><span className="qodef-info-separator-single"></span><a href="#" rel="tag">Hoops</a> <div className="qodef-info-separator-end"></div>
													</div>
													<div className="qodef-additional-holder">
														<div className="qodef-woo-product-price price"><span className="woocs_price_code" data-currency="" data-redraw-id="647831b67c44b" data-product-id="2717"><span className="woocommerce-Price-amount amount">68.00<span className="woocommerce-Price-currencySymbol">&#036;</span></span></span></div>
															<a href="#" data-quantity="1" className="button wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id="2717" data-product_sku="114" aria-label="Add &ldquo;Necklaces Hoop&rdquo; to your cart" rel="nofollow">Add to cart</a> 
													</div>
													<div className="qodef-e-bottom"></div>
												</div>
											</div>
										</li>
										<li className="qodef-e swiper-slide qodef-item--full product type-product post-2721 status-publish instock product_cat-golden-line product_tag-gold product_tag-jewerly has-post-thumbnail shipping-taxable purchasable product-type-simple">
											<div className="qodef-e-inner">
												<div className="qodef-e-media">
													<div className="qodef-e-media-image">
														<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"> 
														<img width="400" height="388" src={shoplist5} className="attachment-full size-full" alt="g" decoding="async" loading="lazy" srcSet={shoplist5}  sizes="(max-width: 400px) 100vw, 400px" /> </a> </div>
														<a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"></a> 
												</div>
												<div className="qodef-e-content">
													<div className="qodef-e-top-holder">
														<h6 itemProp="name" className="qodef-woo-product-title entry-title">
															<a itemProp="url" className="qodef-woo-product-title-link" href="#">Eyes Earrings	</a>
														</h6>
													</div>
													<div className="qodef-e-info">
														<a href="#" rel="tag">Golden Line</a> <div className="qodef-info-separator-end"></div>
													</div>
													<div className="qodef-additional-holder">
														<div className="qodef-woo-product-price price"><span className="woocs_price_code" data-currency="" data-redraw-id="647831b67d8e2" data-product-id="2721"><span className="woocommerce-Price-amount amount">58.00<span className="woocommerce-Price-currencySymbol">&#036;</span></span></span></div>
														<a href="#" data-quantity="1" className="button wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id="2721" data-product_sku="115" aria-label="Add &ldquo;Eyes Earrings&rdquo; to your cart" rel="nofollow">Add to cart</a> 
													</div>
													<div className="qodef-e-bottom"></div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default Collection
