import React from 'react'

const Video = () => {
  return (
    <div>
       <section className="elementor-section elementor-top-section elementor-element elementor-element-6cbfc0a elementor-section-full_width elementor-section-height-min-height elementor-section-height-default elementor-section-items-middle" data-id="6cbfc0a" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;video&quot;,&quot;background_video_link&quot;:&quot;https:\/\/ascella.qodeinteractive.com\/wp-content\/uploads\/2023\/01\/h2-video-1.mp4&quot;,&quot;background_play_on_mobile&quot;:&quot;yes&quot;}">
			<div className="elementor-background-video-container">
				<video className="elementor-background-video-hosted elementor-html5-video" autoPlay muted playsInline loop></video>
			</div>
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e1c05c3" data-id="e1c05c3" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-59c7b3e elementor-widget__width-auto elementor-widget-tablet__width-auto elementor-widget elementor-widget-ascella_core_info_section" data-id="59c7b3e" data-element_type="widget" data-widget_type="ascella_core_info_section.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-info-section qodef-layout--background-text  qodef-background-text-pos--top-left">
									<div className="qodef-m-background-text">
									</div>
									<div className="qodef-m-info">
										<h2 className="qodef-m-title" style={{color: "#FFFFFF"}}>Special Offer<br /> -20% off</h2>
										<div className="qodef-m-button">
											<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--outlined  qodef-html--link" href="#" target="_self" style={{color: "#FFFFFF",bordercolor: "#FFFFFF"}}> <span className="qodef-m-text">Shop now</span></a> 
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default Video
