import React, { useState } from "react";
import detailimg1 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-1.jpg';
import detailimg2 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-2.jpg';
import detailimg3 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-3.jpg';
import detailimg4 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-4.jpg';
import detailimg5 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-5.jpg';
import detailimg6 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-6.jpg';
import dataimg1 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-1-480x643.jpg';
import dataimg2 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-2-480x592.jpg';
import dataimg3 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-3-480x592.jpg';
import dataimg4 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-4-480x592.jpg';
import dataimg5 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-5-480x592.jpg';
import dataimg6 from '../assets/img/uploads/2022/12/h2-product-sl-2-img-6-480x592.jpg';
import { NavLink } from "react-router-dom";
// import '../assets/css/animate.min.css';
// import '../assets/css/icons/font-awesome/assets/css/all.min3781.css';
// import '../assets/css/swiper.min.css';
// import '../assets/css/main.min.css';
// import '../assets/css/magnific-popup.css';

const ImageSliderDetail = ({ imgs = [{ url: "" }] }) => {
const [mainImage, setMainImage] = useState(imgs[0]);
  return (
    <div className="qodef-woo-single-image">
        <div className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images qodef-position--left" data-columns="4" style={{opacity: 0, transition: "opacity .25s ease-in-out"}}>
            <figure className="woocommerce-product-gallery__wrapper">
                <div data-thumb={dataimg1} data-thumb-alt="d" className="woocommerce-product-gallery__image">
                    <a href={dataimg1}>
                        <img width="800" height="1072" src={mainImage.url} className="wp-post-image" alt="d" decoding="async" loading="lazy" title="h2-product-sl-2-img-1" data-caption="" data-src={mainImage.url} data-large_image={mainImage.url} data-large_image_width="800" data-large_image_height="1072" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-1.jpg 800w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-1-480x643.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-1-224x300.jpg 224w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-1-764x1024.jpg 764w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-1-768x1029.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-1-400x536.jpg 400w" sizes="(max-width: 800px) 100vw, 800px" />
                    </a>
                </div>
                <div className="qodef-woo-thumbnails-wrapper">
                    <div data-thumb={dataimg2} data-thumb-alt="d" className="woocommerce-product-gallery__image">
                          <a href={dataimg2}>
                        <img width="480" height="592" src={mainImage.url} className="" alt="d" decoding="async" loading="lazy" title="h2-product-sl-2-img-2" data-caption="" data-src={mainImage.url} data-large_image={mainImage.url} data-large_image_width="600" data-large_image_height="740" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-2-480x592.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-2-243x300.jpg 243w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-2-400x493.jpg 400w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-2.jpg 600w" sizes="(max-width: 480px) 100vw, 480px" /></a>
                    </div>
                                                {/* <div data-thumb={dataimg3} data-thumb-alt="d" className="woocommerce-product-gallery__image">
                                                    <a href={detailimg3}>
                                                        <img width="480" height="592" src={detailimg3} className="" alt="d" decoding="async" loading="lazy" title="h2-product-sl-2-img-3" data-caption="" data-src="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-3.jpg" data-large_image="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-3.jpg" data-large_image_width="600" data-large_image_height="740" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-3-480x592.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-3-243x300.jpg 243w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-3-400x493.jpg 400w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-3.jpg 600w" sizes="(max-width: 480px) 100vw, 480px" />
                                                    </a>
                                                </div>
                                                <div data-thumb={dataimg4} data-thumb-alt="d" className="woocommerce-product-gallery__image">
                                                    <a href={detailimg4}>
                                                        <img width="480" height="592" src={detailimg4} className="" alt="d" decoding="async" loading="lazy" title="h2-product-sl-2-img-4" data-caption="" data-src="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-4.jpg" data-large_image="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-4.jpg" data-large_image_width="600" data-large_image_height="740" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-4-480x592.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-4-243x300.jpg 243w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-4-400x493.jpg 400w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-4.jpg 600w" sizes="(max-width: 480px) 100vw, 480px" /></a>
                                                </div>
                                                <div data-thumb={dataimg5} data-thumb-alt="d" className="woocommerce-product-gallery__image">
                                                    <a href={detailimg5}>
                                                        <img width="480" height="592" src={detailimg5} className="" alt="d" decoding="async" loading="lazy" title="h2-product-sl-2-img-5" data-caption="" data-src="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-5.jpg" data-large_image="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-5.jpg" data-large_image_width="600" data-large_image_height="740" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-5-480x592.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-5-243x300.jpg 243w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-5-400x493.jpg 400w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-5.jpg 600w" sizes="(max-width: 480px) 100vw, 480px" /></a>
                                                </div>
                                                <div data-thumb={dataimg6} data-thumb-alt="d" className="woocommerce-product-gallery__image">
                                                    <a href={detailimg6}>
                                                        <img width="480" height="592" src={detailimg6} className="" alt="d" decoding="async" loading="lazy" title="h2-product-sl-2-img-6" data-caption="" data-src="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-6.jpg" data-large_image="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-6.jpg" data-large_image_width="600" data-large_image_height="740" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-6-480x592.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-6-243x300.jpg 243w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-6-400x493.jpg 400w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-product-sl-2-img-6.jpg 600w" sizes="(max-width: 480px) 100vw, 480px" /></a>
                                                </div> */}
                </div> 
            </figure>
        </div>
    </div>
  )
}

export default ImageSliderDetail;

