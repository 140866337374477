import React from 'react';
import mainslider1 from '../assets/img/uploads/2023/01/h2-prod-list-7-480x604.jpg';
import mainslider2 from '../assets/img/uploads/2023/01/home-2-product-slider-1.jpg';
import mainslider3 from '../assets/img/uploads/2023/01/h2-prod-list-7-1-480x604.jpg';
import mainslider4 from '../assets/img/uploads/2023/01/home-2-product-slider-2.jpg';
import mainslider5 from '../assets/img/uploads/2023/01/h2-prod-list-8-480x604.jpg';
import mainslider6 from '../assets/img/uploads/2022/12/h2-img-1.jpg';

const slider = () => {
  return (
    <div>
        <section className="elementor-section elementor-top-section elementor-element elementor-element-80d3118 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="80d3118" data-element_type="section">
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a391d2a" data-id="a391d2a" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-7e6aabe elementor-widget elementor-widget-ascella_core_product_showcase" data-id="7e6aabe" data-element_type="widget" data-widget_type="ascella_core_product_showcase.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-woo-product-showcase-slider qodef-swiper-container" data-options="{&quot;slideAnimation&quot;:&quot;fade&quot;,&quot;slidesPerView&quot;:1,&quot;spaceBetween&quot;:0,&quot;speed&quot;:6000,&quot;speedAnimation&quot;:100,&quot;loop&quot;:true}">
									<div className="swiper-wrapper">
										<div className="qodef-e swiper-slide">
											<div className="qodef-e-inner">
												<div className="qodef-e-content">
													<div className="qodef-e-info-holder">
														<h2 itemProp="name" className="qodef-e-title entry-title">
															<a itemProp="url" className="qodef-e-link" href="/">Elegant Femininity	</a>
														</h2>
														<p itemProp="description" className="qodef-e-text">Amet nisl suscipit adipiscing bibendum est ultricies integer. Risus quis varius quam.</p>
														<div className="qodef-m-button">
															<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--filled  qodef-html--link" href="#" target="_self"> 
																<span className="qodef-m-text">Shop Now</span>
															</a>
														</div>
													</div>
												</div>
												<div className="qodef-e-media">
													<img width="890" height="890" src={mainslider6} className="attachment-full size-full" alt="s" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-img-1.jpg 890w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-img-1-480x480.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-img-1-100x100.jpg 100w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-img-1-800x800.jpg 800w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-img-1-300x300.jpg 300w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-img-1-150x150.jpg 150w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-img-1-768x768.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-img-1-650x650.jpg 650w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-img-1-400x400.jpg 400w" sizes="(max-width: 890px) 100vw, 890px" /> 
												</div>
												<div className="qodef-e-product">
													<div className="qodef-e-inner">
														<div className="qodef-e-media">
															<div className="qodef-e-media-image">
																<span className="qodef-woo-product-mark qodef-woo-onsale">Sale</span> 
																<img width="480" height="604" src={mainslider1} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-7-480x604.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-7-238x300.jpg 238w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-7-768x967.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-7-400x504.jpg 400w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-7.jpg 800w" sizes="(max-width: 480px) 100vw, 480px" /> 
															</div>
														</div>
														<div className="qodef-e-media-inner">
															<h6 itemProp="name" className="qodef-woo-product-title entry-title">
																<a itemProp="url" className="qodef-woo-product-title-link" href="#">Nouvates Earrings	</a>
															</h6>
															<div className="qodef-woo-product-price price">
																<span className="woocs_price_code" data-currency="" data-redraw-id="647831b66b85a" data-product-id="3146">
																	<del aria-hidden="true"><span className="woocommerce-Price-amount amount">88.00<span className="woocommerce-Price-currencySymbol">&#036;</span></span></del> 
																	<ins><span className="woocommerce-Price-amount amount">79.00<span className="woocommerce-Price-currencySymbol">&#036;</span></span></ins>
																</span>
															</div>
														</div>
														<a className="qodef-e-product-link" href="#" target="_self"></a>
													</div>
												</div>
											</div>
										</div>
										<div className="qodef-e swiper-slide">
											<div className="qodef-e-inner">
												<div className="qodef-e-content">
												<div className="qodef-e-info-holder">
												<h2 itemProp="name" className="qodef-e-title entry-title">
												<a itemProp="url" className="qodef-e-link" href="/">
												Unique &amp; Expresiv </a>
												</h2>
												<p itemProp="description" className="qodef-e-text">Amet nisl suscipit adipiscing bibendum est ultricies integer. Risus quis varius quam.</p>
												<div className="qodef-m-button">
												<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--filled  qodef-html--link" href="#" target="_self"> <span className="qodef-m-text">Shop Now</span></a> </div>
												</div>
												</div>
												<div className="qodef-e-media">
													<img width="890" height="890" src={mainslider2} className="attachment-full size-full" alt="c" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-1.jpg 890w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-1-480x480.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-1-100x100.jpg 100w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-1-800x800.jpg 800w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-1-300x300.jpg 300w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-1-150x150.jpg 150w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-1-768x768.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-1-650x650.jpg 650w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-1-400x400.jpg 400w" sizes="(max-width: 890px) 100vw, 890px" /> 
												</div>
												<div className="qodef-e-product">
													<div className="qodef-e-inner">
														<div className="qodef-e-media">
															<div className="qodef-e-media-image">
																<img width="480" height="604" src={mainslider3} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="f" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-7-1-480x604.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-7-1-238x300.jpg 238w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-7-1-768x967.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-7-1-400x504.jpg 400w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-7-1.jpg 800w" sizes="(max-width: 480px) 100vw, 480px" /> 
															</div>
														</div>
														<div className="qodef-e-media-inner">
															<h6 itemProp="name" className="qodef-woo-product-title entry-title">
																<a itemProp="url" className="qodef-woo-product-title-link" href="#">Necklace pearl	</a>
															</h6>
															<div className="qodef-woo-product-price price">
																<span className="woocs_price_code" data-currency="" data-redraw-id="647831b66d461" data-product-id="4995">
																	<span className="woocommerce-Price-amount amount">82.00<span className="woocommerce-Price-currencySymbol">&#036;</span></span>
																</span>
															</div>
														</div>
															<a className="qodef-e-product-link" href="#" target="_self"></a>
													</div>
												</div>
											</div>
										</div>
										<div className="qodef-e swiper-slide">
											<div className="qodef-e-inner">
											<div className="qodef-e-content">
											<div className="qodef-e-info-holder">
											<h2 itemProp="name" className="qodef-e-title entry-title">
											<a itemProp="url" className="qodef-e-link" href="#">
											Bold &amp; Energetic	</a>
											</h2>
											<p itemProp="description" className="qodef-e-text">Amet nisl suscipit adipiscing bibendum est ultricies integer. Risus quis varius quam.</p>
											<div className="qodef-m-button">
											<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--filled  qodef-html--link" href="#" target="_self"> <span className="qodef-m-text">Shop Now</span></a> </div>
											</div>
											</div>
											<div className="qodef-e-media">
												<img width="890" height="890" src={mainslider4} className="attachment-full size-full" alt="" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-2.jpg 890w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-2-480x480.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-2-100x100.jpg 100w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-2-800x800.jpg 800w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-2-300x300.jpg 300w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-2-150x150.jpg 150w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-2-768x768.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-2-650x650.jpg 650w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/home-2-product-slider-2-400x400.jpg 400w" sizes="(max-width: 890px) 100vw, 890px" /> 
											</div>
											<div className="qodef-e-product">
												<div className="qodef-e-inner">
													<div className="qodef-e-media">
														<div className="qodef-e-media-image">
															<img width="480" height="604" src={mainslider5} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-8-480x604.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-8-238x300.jpg 238w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-8-768x967.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-8-400x504.jpg 400w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/01/h2-prod-list-8.jpg 800w" sizes="(max-width: 480px) 100vw, 480px" /> 
														</div>
													</div>
													<div className="qodef-e-media-inner">
														<h6 itemProp="name" className="qodef-woo-product-title entry-title">
															<a itemProp="url" className="qodef-woo-product-title-link" href="#">Black Flowers	</a>
														</h6>
														<div className="qodef-woo-product-price price">
															<span className="woocs_price_code" data-currency="" data-redraw-id="647831b66fa57" data-product-id="4017">
																<span className="woocommerce-Price-amount amount">84.00<span className="woocommerce-Price-currencySymbol">&#036;</span></span>
															</span>
														</div>
													</div>
													<a className="qodef-e-product-link" href="#" target="_self"></a>
												</div>
											</div>
										</div>
										</div>
									</div>
									<div className="swiper-button-prev ">
										<svg className="qodef-svg--slider-arrow-left" width="24" height="50" x="0" y="0" viewBox="0 0 24 50">
											<path d="M22.7.3L.7 24.8l22 24.5"></path>
										</svg>
									</div>
									<div className="swiper-button-next ">
										<svg className="qodef-svg--slider-arrow-right" width="24" height="50" x="0" y="0" viewBox="0 0 24 50">
											<path d="M.4.3l22 24.5-22 24.5"></path>
										</svg>
									</div>
									<div className="swiper-pagination"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default slider;