import React from 'react';
import smallvideo from '../assets/img/uploads/2023/01/h2-video-2.mp4';
import smallbanner1 from '../assets/img/uploads/2023/03/h2-img-2-ver-2.jpg';

const Introduction = () => {
  return (
    <div>
		<section className="elementor-section elementor-top-section elementor-element elementor-element-b46476a elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="b46476a" data-element_type="section">
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5d0060d" data-id="5d0060d" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-75ac5aa elementor-widget__width-initial elementor-widget-laptop__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget-widescreen__width-initial elementor-widget elementor-widget-ascella_core_single_image" data-id="75ac5aa" data-element_type="widget" data-widget_type="ascella_core_single_image.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-single-image qodef-layout--default   qodef--has-decoration qodef--custom-appear ">
									<div className="qodef-m-image">
										<img width="1000" height="790" src={smallbanner1} className="attachment-full size-full" alt="h" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2023/03/h2-img-2-ver-2.jpg 1000w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/03/h2-img-2-ver-2-480x379.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/03/h2-img-2-ver-2-800x632.jpg 800w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/03/h2-img-2-ver-2-300x237.jpg 300w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/03/h2-img-2-ver-2-768x607.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2023/03/h2-img-2-ver-2-400x316.jpg 400w" sizes="(max-width: 1000px) 100vw, 1000px" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
        <section className="elementor-section elementor-top-section elementor-element elementor-element-8725a39 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="8725a39" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-319c0b1" data-id="319c0b1" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-b535108 elementor-widget__width-initial elementor-widget-laptop__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-ascella_core_video_holder" data-id="b535108" data-element_type="widget" data-widget_type="ascella_core_video_holder.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-video-holder   ">
									<div className="qodef-m-video">
										<video autoPlay="autoPlay" loop="loop" muted="muted" playsInline="">
											<source src={smallvideo} />
										</video>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4fb0723" data-id="4fb0723" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-00cffc9 elementor-widget__width-initial elementor-widget-laptop__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-ascella_core_info_section" data-id="00cffc9" data-element_type="widget" data-widget_type="ascella_core_info_section.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-info-section qodef-layout--background-text  qodef-background-text-pos--top-left">
									<div className="qodef-m-background-text"></div>
									<div className="qodef-m-info">
										<h2 className="qodef-m-title" style={{color: "#CBCBCB"}}>Our Jewelerss	</h2>
										<div className="qodef-m-text"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporus incididunt ut labore et dolore magna aliqua. Amet nisl suscipitis adipiscing bibendum est ultricies integer.</p></div>
									</div>
								</div>
							</div>
						</div>
						<div className="elementor-element elementor-element-dc9419d elementor-widget__width-initial elementor-widget-laptop__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-ascella_core_info_section" data-id="dc9419d" data-element_type="widget" data-widget_type="ascella_core_info_section.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-info-section qodef-layout--background-text  qodef-background-text-pos--top-left">
									<div className="qodef-m-background-text">
									</div>
									<div className="qodef-m-info">
										<div className="qodef-m-text"><p>Amet nulla facilisi morbi tempus iaculis urna. Tempus urna et pharetra pharetrausis massa massa ultricies suis amet.</p></div>
										<div className="qodef-m-button">
											<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--outlined  qodef-html--link" href="#" target="_self"> 
											<span className="qodef-m-text">Read more</span></a> 
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default Introduction;