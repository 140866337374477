import React from 'react';
import footerlogo from '../assets/img/uploads/2023/03/footer-logo-clients-img-x2.png';

const Footer = () => {
  return (
    <div>
        <footer id="qodef-page-footer" role="contentinfo">
			<div id="qodef-page-footer-top-area">
				<div id="qodef-page-footer-top-area-inner" className="qodef-content-full-width qodef-layout-columns--4-predefined">
					<div className="qodef-grid qodef-layout--columns qodef-responsive--custom qodef-col-num--4">
						<div className="qodef-grid-inner">
							<div className="qodef-grid-item">
							<div id="ascella_core_title_widget-3" className="widget widget_ascella_core_title_widget" data-area="qodef-footer-top-area-column-1"> 
								<h4 className="qodef-widget-title" style={{marginbottom: "19px"}}>Ascella Jewelry Shop </h4>
							</div>
							<div id="ascella_core_single_image-2" className="widget widget_ascella_core_single_image" data-area="qodef-footer-top-area-column-1">
								<div className="qodef-shortcode qodef-m  qodef-single-image qodef-layout--default  qodef--retina  "> 
									<div className="qodef-m-image"> <a itemProp="url" href="#" target="_self"> 
										<img itemProp="image" src={footerlogo}  width="206" height="21" alt="" /> </a> 
									</div> 
								</div>
							</div> 
						</div>
						<div className="qodef-grid-item">
							<div id="ascella_core_title_widget-4" className="widget widget_ascella_core_title_widget" data-area="qodef-footer-top-area-column-2"> 
								<p className="qodef-widget-title" style={{marginbottom: "6px"}}>General </p>
							</div>
							<div id="nav_menu-2" className="widget widget_nav_menu" data-area="qodef-footer-top-area-column-2">
								<div className="menu-footer-menu-1-container">
									<ul id="menu-footer-menu-1" className="menu"><li id="menu-item-208" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-208"><a href="#">Shipping</a></li>
										<li id="menu-item-209" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-209"><a href="#">Order Status</a></li>
										<li id="menu-item-210" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-210"><a href="#">Exchanges &#038; Returns</a></li>
										<li id="menu-item-211" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-211"><a href="#">Size Guide</a></li>
										<li id="menu-item-212" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-212"><a href="#">Jewelry Care</a></li>
										<li id="menu-item-213" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-213"><a href="#">FAQ</a></li>
									</ul>
								</div>
							</div> 
						</div>
						<div className="qodef-grid-item">
							<div id="ascella_core_title_widget-5" className="widget widget_ascella_core_title_widget" data-area="qodef-footer-top-area-column-3"> 
								<p className="qodef-widget-title" style={{marginbottom: "6px"}}>About </p>
							</div>
							<div id="nav_menu-3" className="widget widget_nav_menu" data-area="qodef-footer-top-area-column-3">
								<div className="menu-footer-menu-2-container">
									<ul id="menu-footer-menu-2" className="menu">
										<li id="menu-item-214" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-214"><a href="#">Our World</a></li>
										<li id="menu-item-215" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-215"><a href="#">Our Packaging</a></li>
										<li id="menu-item-216" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-216"><a href="#">Our Client</a></li>
										<li id="menu-item-217" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-217"><a href="#">Stories</a></li>
										<li id="menu-item-218" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-218"><a href="#">Gift Cards</a></li>
										</ul>
									</div>
								</div> 
							</div>
							<div className="qodef-grid-item">
								<div id="ascella_core_title_widget-6" className="widget widget_ascella_core_title_widget" data-area="qodef-footer-top-area-column-4"> 
									<p className="qodef-widget-title" style={{marginbottom: "6px"}}>Categories </p>
								</div>
								<div id="nav_menu-4" className="widget widget_nav_menu" data-area="qodef-footer-top-area-column-4">
									<div className="menu-footer-menu-3-container">
										<ul id="menu-footer-menu-3" className="menu"><li id="menu-item-219" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-219"><a href="#">New In</a></li>
										<li id="menu-item-220" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-220"><a href="#">Earrings</a></li>
										<li id="menu-item-221" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-221"><a href="#">Rings</a></li>
										<li id="menu-item-222" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-222"><a href="#">Necklace</a></li>
										<li id="menu-item-223" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-223"><a href="#">Bracelets</a></li>
										<li id="menu-item-224" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-224"><a href="#">Accessories</a></li>
										<li id="menu-item-225" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-225"><a href="#">All Jewelry</a></li>
										</ul>
									</div>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="qodef-page-footer-bottom-area">
				<div id="qodef-page-footer-bottom-area-inner" className="qodef-content-full-width">
					<div className="qodef-grid qodef-layout--columns qodef-responsive--custom qodef-col-num--2 qodef-col-num--680--1 qodef-col-num--480--1 qodef-gutter--no qodef-alignment--space-between">
						<div className="qodef-grid-inner">
							<div className="qodef-grid-item">
								<div id="block-56" className="widget widget_block" data-area="qodef-footer-bottom-area-column-1">
									<p className="qodef--custom-underline" style={{fontsize:"12px"}}>© 2023 <a rel="noreferrer noopener" href="#" target="_blank">Qode Interactive,</a> All Rights Reserved</p>
								</div> 
							</div>
							<div className="qodef-grid-item">
								<div id="block-57" className="widget widget_block" data-area="qodef-footer-bottom-area-column-2">
									<p className="qodef--custom-underline" style={{fontsize:"12px"}}>
										<a href="#" target="_blank" rel="noreferrer noopener"><span className="__cf_email__" data-cfemail="">email@gmail.com</span></a>
									</p>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
    	<a id="qodef-back-to-top" href="#" className="qodef--predefined qodef--on">
			<span className="qodef-back-to-top-icon">
				<svg width="14" height="8.8" viewBox="0 0 14 8.8">
				<path
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					d="M13.6 8.4L7.2.8.4 8.4"
					className="qodef-svg--back-to-top qodef-e-back-to-top-icon"
				></path>
				</svg> 
			</span>
		</a>
    </div>
  )
}

export default Footer;