import React from 'react';
import blogimg1 from '../assets/img/uploads/2022/12/h2-blog-img-1.jpg';
import blogimg2 from '../assets/img/uploads/2022/12/h2-blog-img-2.jpg';
// import blogimg02 from '../assets/img/uploads/2022/12/h2-blog-img-2-480x568.jpg';
// import blogimg03 from '../assets/img/uploads/2022/12/h2-blog-img-2-800x947.jpg';
// import blogimg04 from '../assets/img/uploads/2022/12/h2-blog-img-2-253x300.jpg';
// import blogimg05 from '../assets/img/uploads/2022/12/h2-blog-img-2-865x1024.jpg';
// import blogimg06 from '../assets/img/uploads/2022/12/h2-blog-img-2-768x909.jpg';
// import blogimg07 from '../assets/img/uploads/2022/12/h2-blog-img-2-400x474.jpg';


const Blog = () => {
  return (
    <div>
        <section className="elementor-section elementor-top-section elementor-element elementor-element-7d645b6 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7d645b6" data-element_type="section">
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f69a7e9" data-id="f69a7e9" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-2173275 elementor-widget elementor-widget-text-editor" data-id="2173275" data-element_type="widget" data-widget_type="text-editor.default">
							<div className="elementor-widget-container">
								<h3>latest Stories</h3> 
							</div>
						</div>
						<div className="elementor-element elementor-element-d8e4c69 elementor-widget elementor-widget-ascella_core_blog_list" data-id="d8e4c69" data-element_type="widget" data-widget_type="ascella_core_blog_list.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-blog qodef-item-layout--info-below  qodef--border qodef-grid qodef-layout--columns  qodef-gutter--enormous qodef-vertical-gutter--medium qodef-col-num--2 qodef-item-layout--info-below qodef-pagination--off qodef-responsive--custom qodef-col-num--1440--2 qodef-col-num--1366--2 qodef-col-num--1024--2 qodef-col-num--768--2 qodef-col-num--680--1 qodef-col-num--480--1" data-options="{&quot;plugin&quot;:&quot;ascella_core&quot;,&quot;module&quot;:&quot;blog\/shortcodes&quot;,&quot;shortcode&quot;:&quot;blog-list&quot;,&quot;post_type&quot;:&quot;post&quot;,&quot;next_page&quot;:&quot;2&quot;,&quot;max_pages_num&quot;:3,&quot;behavior&quot;:&quot;columns&quot;,&quot;images_proportion&quot;:&quot;full&quot;,&quot;columns&quot;:&quot;2&quot;,&quot;columns_responsive&quot;:&quot;custom&quot;,&quot;columns_1440&quot;:&quot;2&quot;,&quot;columns_1366&quot;:&quot;2&quot;,&quot;columns_1024&quot;:&quot;2&quot;,&quot;columns_768&quot;:&quot;2&quot;,&quot;columns_680&quot;:&quot;1&quot;,&quot;columns_480&quot;:&quot;1&quot;,&quot;space&quot;:&quot;enormous&quot;,&quot;vertical_space&quot;:&quot;medium&quot;,&quot;posts_per_page&quot;:&quot;2&quot;,&quot;orderby&quot;:&quot;date&quot;,&quot;order&quot;:&quot;ASC&quot;,&quot;additional_params&quot;:&quot;tax&quot;,&quot;tax&quot;:&quot;category&quot;,&quot;tax_slug&quot;:&quot;handmade&quot;,&quot;layout&quot;:&quot;info-below&quot;,&quot;title_tag&quot;:&quot;h4&quot;,&quot;enable_border&quot;:&quot;yes&quot;,&quot;pagination_type&quot;:&quot;no-pagination&quot;,&quot;object_className_name&quot;:&quot;AscellaCore_Blog_List_Shortcode&quot;,&quot;taxonomy_filter&quot;:&quot;category&quot;,&quot;additional_query_args&quot;:{&quot;tax_query&quot;:[{&quot;taxonomy&quot;:&quot;category&quot;,&quot;field&quot;:&quot;slug&quot;,&quot;terms&quot;:&quot;handmade&quot;}]},&quot;space_value&quot;:115}">
									<div className="qodef-grid-inner">
										<article className="qodef-e qodef-blog-item qodef-grid-item qodef-item--full post-1519 post type-post status-publish format-standard has-post-thumbnail hentry category-handmade category-rose tag-jewelry">
											<div className="qodef-e-inner">
												<div className="qodef-e-top-holder">
													<div className="qodef-e-date-holder">
														<a itemProp="dateCreated" href="#" className="entry-date published updated">
														<span className="qodef-e-day">08</span>
														<span className="qodef-e-month">Dec</span>
														</a>
													</div>
													<div className="qodef-e-media-image">
														<a itemProp="url" href="#">
															<img width="1000" height="1184" src={blogimg1} className="attachment-full size-full" alt="w" decoding="async" loading="lazy" srcSet={blogimg1} sizes="(max-width: 1000px) 100vw, 1000px" /> 
														</a>
													</div>
												</div>
												<div className="qodef-e-content">
													<div className="qodef-e-info">
														<a href="#" rel="tag">Handmade</a><span className="qodef-info-separator-single"></span><a href="#" rel="tag">Rose</a>
														<div className="qodef-info-separator-end"></div> 
													</div>
													<div className="qodef-e-text">
														<h4 itemProp="name" className="qodef-e-title entry-title">
															<a itemProp="url" className="qodef-e-title-link" href="#">Invest In Yourself	</a>
														</h4>
														<p itemProp="description" className="qodef-e-excerpt">Ipsum dolor sit amet, consectetur adipiscing elit. Donec ac lectus mi. Curabitur non ligula facilisis, ultrices ex sed, viverra.</p>
													</div>
													<div className="qodef-e-bottom-holder">
														<div className="qodef-e-left">
															<div className="qodef-e-read-more">
																<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--textual  qodef-html--link" href="#" target="_self"> <span className="qodef-m-text">Read More</span></a> 
															</div>
														</div>
													</div>
												</div>
											</div>
										</article>
										<article className="qodef-e qodef-blog-item qodef-grid-item qodef-item--full post-1513 post type-post status-publish format-standard has-post-thumbnail hentry category-handmade category-rose tag-jewelry">
											<div className="qodef-e-inner">
												<div className="qodef-e-top-holder">
													<div className="qodef-e-date-holder">
														<a itemProp="dateCreated" href="#" className="entry-date published updated">
															<span className="qodef-e-day">08</span>
															<span className="qodef-e-month">Dec</span>
														</a>
													</div>
													<div className="qodef-e-media-image">
														<a itemProp="url" href="#">
														<img width="1000" height="1184" src={blogimg2} className="attachment-full size-full" alt="w" decoding="async" srcSet={blogimg2} loading="lazy" sizes="(max-width: 1000px) 100vw, 1000px" /> </a>
													</div>
												</div>
											<div className="qodef-e-content">
												<div className="qodef-e-info">
													<a href="#" rel="tag">Handmade</a><span className="qodef-info-separator-single"></span><a href="#" rel="tag">Rose</a><div className="qodef-info-separator-end"></div> </div>
													<div className="qodef-e-text">
														<h4 itemProp="name" className="qodef-e-title entry-title">
														<a itemProp="url" className="qodef-e-title-link" href="#">
														Empowerment Fund	</a>
														</h4>
														<p itemProp="description" className="qodef-e-excerpt">Ipsum dolor sit amet, consectetur adipiscing elit. Donec ac lectus mi. Curabitur non ligula facilisis, ultrices ex sed, viverra.</p>
													</div>
													<div className="qodef-e-bottom-holder">
														<div className="qodef-e-left">
															<div className="qodef-e-read-more">
																<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--textual  qodef-html--link" href="#" target="_self"> 
																<span className="qodef-m-text">Read More</span></a> 
															</div>
														</div>
													</div>
												</div>
											</div>
										</article>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default Blog;