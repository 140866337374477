import React from 'react';
import categoriSlider1 from '../assets/img/uploads/2023/01/h2-category-img-1-1.jpg';
import categoriSlider2 from '../assets/img/uploads/2023/01/h2-category-img-2-1.jpg';
import categoriSlider3 from '../assets/img/uploads/2023/01/h2-category-img-3-N.jpg';

const Categories = () => {
  return (
    <div>
        <section className="elementor-section elementor-top-section elementor-element elementor-element-f70deab elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="f70deab" data-element_type="section">
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-c5d47bf" data-id="c5d47bf" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-4c92be6 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-ascella_core_info_section" data-id="4c92be6" data-element_type="widget" data-widget_type="ascella_core_info_section.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-info-section qodef-layout--background-text  qodef-background-text-pos--top-left">
									<div className="qodef-m-background-text"></div>
									<div className="qodef-m-info">
										<h3 className="qodef-m-title">Shop by categories spring collection	</h3>
										<div className="qodef-m-text"><p>In tellus integer feugiat scelerisque varius morbi enim. Non nisi est sit amet facilisis. Sagittis vitae et leo duis ut. Nullam eget.</p></div>
									</div>
								</div>
							</div>
						</div>
						<div className="elementor-element elementor-element-1493b36 elementor-widget elementor-widget-spacer" data-id="1493b36" data-element_type="widget" data-widget_type="spacer.default">
							<div className="elementor-widget-container">
								<div className="elementor-spacer">
									<div className="elementor-spacer-inner"></div>
								</div>
							</div>
						</div>
						<div className="elementor-element elementor-element-75f4d2f elementor-widget__width-initial elementor-widget elementor-widget-ascella_core_button" data-id="75f4d2f" data-element_type="widget" data-widget_type="ascella_core_button.default">
							<div className="elementor-widget-container">
								<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--filled  qodef-html--link" href="#" target="_self">
									<span className="qodef-m-text">Shop now</span>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-7896567" data-id="7896567" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-d57bb21 elementor-widget elementor-widget-ascella_core_product_category_list" data-id="d57bb21" data-element_type="widget" data-widget_type="ascella_core_product_category_list.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-woo-shortcode qodef-woo-product-category-list qodef-item-layout--info-below qodef-content-align--center     qodef-grid qodef-swiper-container  qodef-gutter--medium  qodef-col-num--2 qodef-item-layout--info-below qodef-responsive--custom qodef-col-num--1440--2 qodef-col-num--1366--2 qodef-col-num--1024--2 qodef-col-num--768--2 qodef-col-num--680--1 qodef-col-num--480--1" data-options="{&quot;slidesPerView&quot;:&quot;2&quot;,&quot;spaceBetween&quot;:40,&quot;loop&quot;:true,&quot;autoPlay&quot;:true,&quot;speed&quot;:&quot;3500&quot;,&quot;speedAnimation&quot;:&quot;&quot;,&quot;slideAnimation&quot;:&quot;&quot;,&quot;slideDecimal&quot;:&quot;.4&quot;,&quot;direction&quot;:&quot;&quot;,&quot;centeredSlides&quot;:&quot;&quot;,&quot;sliderScroll&quot;:&quot;&quot;,&quot;loopAdditionalSlides&quot;:&quot;&quot;,&quot;customStages&quot;:true,&quot;slidesPerView1440&quot;:&quot;2&quot;,&quot;slidesPerView1366&quot;:&quot;2&quot;,&quot;slidesPerView1024&quot;:&quot;2&quot;,&quot;slidesPerView768&quot;:&quot;2&quot;,&quot;slidesPerView680&quot;:&quot;1&quot;,&quot;slidesPerView480&quot;:&quot;1&quot;}">
									<div className="swiper-wrapper">
										<div className="qodef-e qodef--has-appear swiper-slide qodef-item--full product-category product last">
											<div className="qodef-e-inner">
												<div className="qodef-e-image">
													<img width="445" height="560" src={categoriSlider2} className="attachment-full size-full" alt="s" decoding="async" loading="lazy" srcSet={categoriSlider2} sizes="(max-width: 445px) 100vw, 445px" /> <a itemProp="url" className="qodef-e-category-link" href="#"></a>
												</div>
												<div className="qodef-e-content">
													<h6 className="woocommerce-loop-category__title">
														<a href="#">Perfect Gift</a>
													</h6>
													<p className="woocommerce-loop-category__excerpt">Scelerisque in mauris ut malesu</p>
													<div className="qodef-e-read-more">
														<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--textual  qodef-html--link" href="#" target="_self"> <span className="qodef-m-text">Find More</span></a>
													</div>
												</div>
											</div>
										</div>
										<div className="qodef-e qodef--has-appear swiper-slide qodef-item--full product-category product first">
											<div className="qodef-e-inner">
												<div className="qodef-e-image">
													<img width="445" height="560" src={categoriSlider1} className="attachment-full size-full" alt="s" decoding="async" loading="lazy" srcSet={categoriSlider1} sizes="(max-width: 445px) 100vw, 445px" /> <a itemProp="url" className="qodef-e-category-link" href="#"></a>
												</div>
												<div className="qodef-e-content">
													<h6 className="woocommerce-loop-category__title">
														<a href="#">Gold Colection	</a>
													</h6>
													<p className="woocommerce-loop-category__excerpt">Mauris condime ntum males</p>
													<div className="qodef-e-read-more">
														<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--textual  qodef-html--link" href="#" target="_self"> <span className="qodef-m-text">Find More</span></a></div>
												</div>
											</div>
										</div>
										<div className="qodef-e qodef--has-appear swiper-slide qodef-item--full product-category product">
											<div className="qodef-e-inner">
												<div className="qodef-e-image">
													<img width="445" height="560" src={categoriSlider3} className="attachment-full size-full" alt="S" decoding="async" loading="lazy" srcSet={categoriSlider3} sizes="(max-width: 445px) 100vw, 445px" /> <a itemProp="url" className="qodef-e-category-link" href="#"></a>
												</div>
												<div className="qodef-e-content">
													<h6 className="woocommerce-loop-category__title">
														<a href="#">Gold Accessories	</a>
													</h6>
													<p className="woocommerce-loop-category__excerpt">C ondime ntum males lauris</p>
													<div className="qodef-e-read-more">
														<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--textual  qodef-html--link" href="#" target="_self"> <span className="qodef-m-text">Find More</span></a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default Categories;
