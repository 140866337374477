import React from 'react';
import './App.css';

import './assets/css/animate.min.css';
import './assets/css/icons/elegant-icons/assets/css/elegant-icons.min3781.css';
import './assets/css/icons/font-awesome/assets/css/all.min3781.css';

import './assets/css/classic-themes.min.css';
import './assets/css/styles-contact.css';
import './assets/css/swiper.min.css';
import './assets/css/main.min.css';
import './assets/css/ascella-core.min.css';
import './assets/css/magnific-popup.css';

//import 'https://fonts.googleapis.com/css?family=Italiana%3A300%2C400%2C500%2C600%2C700%7CPoppins%3A300%2C400%2C500%2C600%2C700&amp;subset=latin-ext&amp;display=swap&amp;ver=1.0.0';
import './assets/fonts/fonts.css';
import './assets/css/style.css';
import './assets/css/elementor.css';

import './assets/css/ascella-membership.min.css';
import './assets/css/style3781.css';
	
import './assets/css/elementor/elementor.min.css';
import './assets/css/elementor-icons.min.css';
import './assets/css/custom-frontend-lite.min.css';

import './assets/css/front2fca.css';
import './assets/css/perfect-scrollbar.css';

import './assets/css/mediaelementplayer-legacy.min.css';
import './assets/css/wp-mediaelement.min.css';
import './assets/css/slider-rs69f6d.css';

import Home from './Home';
import HeaderMenu from './component/HeaderMenu';
import Footer from './component/Footer';
import About from './About';
import SingleProduct from './SingleProduct';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import ErrorPage from './ErrorPage';

function App() {
  return (
	<BrowserRouter>
		<HeaderMenu />
			<Routes>
				<Route path="/" element={<Home />}></Route>
				<Route path="/about" element={<About />}></Route>
				<Route path="/:id" element={<SingleProduct />}></Route>
				{/* <Route path="/productdetail/:id" element={<ProductDetail />} /> */}
				<Route path="*" element={<ErrorPage />}></Route>
			</Routes>
		<Footer />
	</BrowserRouter>
  );
}

export default App;
