import React from 'react';
import { NavLink } from 'react-router-dom';

const PageNavigation = ({title}) => {
  return (
    <div className="qodef-page-title qodef-m qodef-title--breadcrumbs qodef-alignment--left qodef-vertical-alignment--header-bottom">
            <div className="qodef-m-inner">
                <div className="qodef-m-content qodef-content-full-width ">
                    <div itemProp="breadcrumb" className="qodef-breadcrumbs">
                        <NavLink itemProp="url" className="qodef-breadcrumbs-link" to="/">
                            <span itemProp="title">Home</span>
                        </NavLink>
                        <span className="qodef-breadcrumbs-separator"></span>
                        {/* <a itemProp="url" className="qodef-breadcrumbs-link" href="#"><span itemProp="title">Shop</span></a>
                        <span className="qodef-breadcrumbs-separator"></span>
                        <a itemProp="url" className="qodef-breadcrumbs-link" href="#"><span itemProp="title">Golden Line</span></a>
                        <span className="qodef-breadcrumbs-separator"></span> */}
                        <span itemProp="title" className="qodef-breadcrumbs-current">{title}</span>
                    </div>
                </div>
            </div>
        </div>
  )
};

export default PageNavigation;
