import React from 'react';
import logo from '../assets/img/uploads/2022/12/logo-dark-img.png';
import menuimg1 from '../assets/img/uploads/2023/02/wide-menu-image-1-n.jpg';
import menuimg2 from  '../assets/img/uploads/2023/01/menu-dd-img-1.jpg';

const HeaderMenu = () => {
    const handleClick = (e) => {
		e.preventDefault();
		console.log('The link was clicked.');
	  }
  return (
	<div>
		<header id="qodef-page-header" role="banner">
			{/* <div id="qodef-page-header-inner" className="">
				<a itemProp="url" className="qodef-header-logo-link qodef-height--set qodef-source--image" href="/" rel="home">
					<img width="204" height="48" src={logo} className="qodef-header-logo-image qodef--main" alt="logo main" itemProp="image" />
					<img width="227" height="52" src={logo} className="qodef-header-logo-image qodef--dark" alt="logo dark" itemProp="image" />
					<img width="227" height="52" src={logo} className="qodef-header-logo-image qodef--light" alt="logo light" itemProp="image" />
				</a>
				<nav className="qodef-header-navigation" role="navigation" aria-label="Top Menu">
					<ul id="menu-main-menu-1" className="menu">
						<li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-30 qodef-menu-item--narrow">
							<a href="#"><span className="qodef-menu-item-text">Home
								<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
									<path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path>
								</svg></span>
							</a>
							<div className="qodef-drop-down-second">
								<div className="qodef-drop-down-second-inner">
									<ul className="sub-menu">
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-5924"><a href="#"><span className="qodef-menu-item-text">Main Home</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-336 current_page_item menu-item-1717"><a href="#"><span className="qodef-menu-item-text">Jewelry Store</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5921"><a href="#"><span className="qodef-menu-item-text">Parallax Home</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5922"><a href="#"><span className="qodef-menu-item-text">Jewelry Grid</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5923"><a href="#"><span className="qodef-menu-item-text">Jewelry Showcase</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1718"><a href="#"><span className="qodef-menu-item-text">Shop Minimal</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3104"><a href="#"><span className="qodef-menu-item-text">Landing</span></a></li>
									</ul>
								</div>
							</div>
						</li>
						<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-31 qodef-menu-item--narrow">
							<a href="#"><span className="qodef-menu-item-text">Pages
								<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
								<path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
								</span>
							</a>
							<div className="qodef-drop-down-second">
								<div className="qodef-drop-down-second-inner">
									<ul className="sub-menu">
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2999"><a href="../about-me/index.html"><span className="qodef-menu-item-text">About Me</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2998"><a href="../about-us/index.html"><span className="qodef-menu-item-text">About Us</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3544"><a href="../size-guide/index.html"><span className="qodef-menu-item-text">Size Guide</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3491"><a href="../gift-card/index.html"><span className="qodef-menu-item-text">Gift Card</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4468"><a href="../our-clients/index.html"><span className="qodef-menu-item-text">Our Clients</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2997"><a href="../get-in-touch/index.html"><span className="qodef-menu-item-text">Get In Touch</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3060"><a href="../contact-us/index.html"><span className="qodef-menu-item-text">Contact Us</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3620"><a href="../faq-page/index.html"><span className="qodef-menu-item-text">FAQ Page</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3659"><a href="../coming-soon/index.html"><span className="qodef-menu-item-text">Coming Soon</span></a></li>
									</ul>
								</div>
							</div>
						</li>
						<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-32 qodef-menu-item--wide">
							<a href="#">
								<span className="qodef-menu-item-text">Shop
									<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
								</span>
							</a>
							<div className="qodef-drop-down-second">
								<div className="qodef-drop-down-second-inner qodef-content-grid">
									<ul className="sub-menu">
										<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1389 qodef--hide-link">
											<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Shop Lists<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
											</span></a>
											<ul className="sub-menu">
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1390"><a href="#"><span className="qodef-menu-item-text">Shop Standard</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2249"><a href="#"><span className="qodef-menu-item-text">Shop Slider</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1462"><a title="New" href="#"><span className="qodef-menu-item-text">Shop Wide List</span><span className="qodef-link-label">New</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1461"><a title="New" href="#"><span className="qodef-menu-item-text">Shop List With Baner</span><span className="qodef-link-label">New</span></a></li>
											</ul>
										</li>
										<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2251 qodef--hide-link">
											<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Filter Types
											<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
											</span></a>
											<ul className="sub-menu">
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1460"><a href="#"><span className="qodef-menu-item-text">Shop Category Images</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1459"><a title="New" href="#"><span className="qodef-menu-item-text">Shop Side Filter</span><span className="qodef-link-label">New</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2243"><a href="#"><span className="qodef-menu-item-text">Standard Category Filter</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1515"><a title="New" href="#"><span className="qodef-menu-item-text">Shop The Look</span><span className="qodef-link-label">New</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2332"><a title="New" href="#"><span className="qodef-menu-item-text">Shop By Collection</span><span className="qodef-link-label">New</span></a></li>
											</ul>
										</li>
										<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-3409 qodef--hide-link">
											<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Shop Layouts
												<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
												</span>
											</a>
											<ul className="sub-menu">
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3471"><a href="#"><span className="qodef-menu-item-text">Two Columns</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3470"><a href="#"><span className="qodef-menu-item-text">Three Columns</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3469"><a href="#"><span className="qodef-menu-item-text">Three Columns Wide</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3467"><a href="#"><span className="qodef-menu-item-text">Four Columns</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3468"><a href="#"><span className="qodef-menu-item-text">Four Columns Wide</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3466"><a href="#"><span className="qodef-menu-item-text">Five Columns Wide</span></a></li>
											</ul>
										</li>
										<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-107 qodef--hide-link">
											<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Shop Pages
												<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
												</span>
											</a>
											<ul className="sub-menu">
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-109"><a href="#"><span className="qodef-menu-item-text">My Account</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-108"><a href="#"><span className="qodef-menu-item-text">Wishlist</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-111"><a href="#"><span className="qodef-menu-item-text">Cart</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-110"><a href="#"><span className="qodef-menu-item-text">Checkout</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3195"><a title="New" href="#"><span className="qodef-menu-item-text">Order Tracking</span><span className="qodef-link-label">New</span></a></li>
											</ul>
										</li>
									</ul>
								</div>
							</div>
						</li>
						<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-134 qodef-menu-item--wide">
							<a href="#"><span className="qodef-menu-item-text">Products
								<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span>
							</a>
							<div className="qodef-drop-down-second">
								<div className="qodef-drop-down-second-inner qodef-content-grid qodef-content-grid">
									<ul className="sub-menu">
										<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-138 qodef--hide-link">
											<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Product Types
											<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
											</span></a>
											<ul className="sub-menu">
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-4973"><a href="#"><span className="qodef-menu-item-text">Standard Product</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-5048"><a href="#"><span className="qodef-menu-item-text">Variable Product</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-4993"><a href="#"><span className="qodef-menu-item-text">Variable Product Colors</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-296"><a href="#"><span className="qodef-menu-item-text">Grouped Product</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-5427"><a href="#"><span className="qodef-menu-item-text">Virtual Product</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-620"><a title="New" href="#"><span className="qodef-menu-item-text">Slider Custom</span><span className="qodef-link-label">New</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-314"><a href="#"><span className="qodef-menu-item-text">Big Images Product</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-295"><a title="New" href="#"><span className="qodef-menu-item-text">Full Width Product</span><span className="qodef-link-label">New</span></a></li>
											</ul>
										</li>
										<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-135 qodef--hide-link">
											<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Product Widgets
												<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
												</span>
											</a>
											<ul className="sub-menu">
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-2227"><a href="#"><span className="qodef-menu-item-text">Video Products</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-3163"><a href="#"><span className="qodef-menu-item-text">Sale Product</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-3164"><a href="#"><span className="qodef-menu-item-text">New Product</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-3709"><a href="#"><span className="qodef-menu-item-text">Sold Product</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-136"><a title="New" href="#"><span className="qodef-menu-item-text">Side Info Product</span><span className="qodef-link-label">New</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-5010"><a title="New" href="#"><span className="qodef-menu-item-text">Countdown Product</span><span className="qodef-link-label">New</span></a></li>
											</ul>
										</li>
										<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3187 qodef--hide-link qodef-mega-menu-widget--enabled">
											<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Editors New Picks</span></a>
											<div className="qodef-mega-menu-widget-holder">
												<div className="widget widget_block" data-area="heder-wide">
													<div className="wp-block-columns is-layout-flex wp-container-5">
														<div className="wp-block-column is-layout-flow">
															<figure className="wp-block-image size-full is-resized">
																<a href="../shop-category-images/index.html">
																	<img decoding="async" loading="lazy" src={menuimg2} alt="" className="wp-image-3407" width="308" height="234" srcSet={menuimg2} sizes="(max-width: 308px) 100vw, 308px" />
																</a>
															</figure>
															<p style={{color: "#000000", margin: "-11px 0 5px 0"}}>Introducting New Collection</p>
															<div className="widget widget_ascella_core_button">
																<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--textual  qodef-html--link" href="#" target="_self" style={{color: "#000000"}}> <span className="qodef-m-text">Shop Now</span></a>
															</div>
														</div>
														<div className="wp-block-column is-layout-flow">
															<figure className="wp-block-gallery has-nested-images columns-default is-cropped wp-block-gallery-2 is-layout-flex">
															<figure className="wp-block-image size-full is-style-default">
																<a href="../shop-side-filter/index.html">
																<img decoding="async" loading="lazy" width="308" height="235" src={menuimg1} alt="" className="wp-image-5213" srcSet={menuimg1} sizes="(max-width: 308px) 100vw, 308px" /></a></figure>
															</figure>
														</div>
													</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</li>
						<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-33 qodef--hide-link qodef-menu-item--narrow">
							<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Portfolio
								<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span>
							</a>
							<div className="qodef-drop-down-second">
								<div className="qodef-drop-down-second-inner">
									<ul className="sub-menu">
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2466"><a href="../portfolio-list/index.html"><span className="qodef-menu-item-text">Gallery List</span></a></li>
										<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2451 qodef--hide-link">
											<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Single Types
											<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span></a>
											<ul className="sub-menu">
												<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2456"><a href="#"><span className="qodef-menu-item-text">Small Images</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2457"><a href="#"><span className="qodef-menu-item-text">Big Images</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2454"><a href="#"><span className="qodef-menu-item-text">Small Slider</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2455"><a href="#"><span className="qodef-menu-item-text">Big Slider</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2453"><a href="#"><span className="qodef-menu-item-text">Small Gallery</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2452"><a href="#"><span className="qodef-menu-item-text">Big Gallery</span></a></li>
											</ul>
										</li>
									</ul>
								</div>
							</div>
						</li>
						<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-34 qodef--hide-link qodef-menu-item--narrow">
							<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Blog<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span></a>
							<div className="qodef-drop-down-second">
								<div className="qodef-drop-down-second-inner">
									<ul className="sub-menu">
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3924"><a href="#"><span className="qodef-menu-item-text">Right Sidebar</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3923"><a href="#"><span className="qodef-menu-item-text">Left Sidebar</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3922"><a href="#"><span className="qodef-menu-item-text">No Sidebar</span></a></li>
										<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2475 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Post Formats<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span></a>
											<ul className="sub-menu">
												<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2474"><a href="#"><span className="qodef-menu-item-text">Standard</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2471"><a href="#"><span className="qodef-menu-item-text">Gallery</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2470"><a href="#"><span className="qodef-menu-item-text">Link</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2469"><a href="#"><span className="qodef-menu-item-text">Quote</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2467"><a href="#"><span className="qodef-menu-item-text">Audio</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2468"><a href="#"><span className="qodef-menu-item-text">Video</span></a></li>
												<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-3925"><a href="#"><span className="qodef-menu-item-text">No Sidebar</span></a></li>
											</ul>
										</li>
									</ul>
								</div>
							</div>
						</li>
					</ul> 
				</nav>
				<div className="qodef-widget-holder qodef--one">
					<div className="widget widget_ascella_membership_login_opener" data-area="home">
						<div className="qodef-login-opener-widget qodef-user-logged--out">
							<a href="#" className="qodef-login-opener">
								<span className="qodef-login-opener-text">
								<svg enableBackground="new 0 0 15.4 15.4" viewBox="0 0 15.4 15.4" className="qodef-svg--account">
								<path d="M3 10.5c1.6-.7 3.2-1 4.7-1s3 .3 4.7 1c1.6.7 2.4 1.5 2.4 2.6v1.8H.6v-1.8c0-1.1.8-2 2.4-2.6zm7.2-3.8c-.7.7-1.5 1-2.5 1s-1.8-.3-2.5-1-1-1.5-1-2.5.3-1.8 1-2.5 1.5-1 2.5-1 1.8.3 2.5 1 1 1.5 1 2.5c.1 1-.3 1.8-1 2.5z"></path>
								</svg>
								</span>
							</a>
						</div>
					</div>
					<div className="widget widget_ascella_core_svg_icon" data-area="home"> 
						<div className="qodef-svg-icon-widget">
							<a href="../wishlist/index.html" target="_self">
								<div className="qodef-m-holder" style={{alignitems:"center"}}>
									<div className="qodef-m-icon" style={{margin: "0 10px 0 8px",strokecolor: "#ffffff00",strokehovercolor: "#ffffff00"}}>
										<svg xlinkHref="http://www.w3.org/2000/svg" viewBox="0 0 16.3 15" width="16"><path d="M11.8 0C13 0 14.1.4 15 1.3s1.3 1.9 1.3 3.2c0 .8-.1 1.5-.4 2.2-.3.7-.9 1.4-1.6 2.3-.8.9-1.5 1.6-2.1 2.2-.6.6-1.6 1.5-2.9 2.7L8.1 15 7 13.9c-1.4-1.3-2.3-2.2-2.9-2.7C3.4 10.6 2.7 9.9 2 9 1.2 8.1.7 7.4.4 6.7.1 6 0 5.2 0 4.5c0-1.3.4-2.3 1.3-3.2S3.2 0 4.5 0C6 0 7.2.6 8.2 1.7 9.1.6 10.3 0 11.8 0zM8.6 12.9c2.5-2.3 4.1-3.9 4.8-4.8 1-1.3 1.6-2.5 1.6-3.6 0-.9-.3-1.7-.9-2.3s-1.4-.9-2.3-.9c-1.1 0-2 .4-2.7 1.3l-1 1.1-.9-1.2c-.7-.8-1.7-1.2-2.7-1.2-.9 0-1.6.3-2.3.9s-.9 1.4-.9 2.3c0 1.1.5 2.3 1.6 3.6.7.9 2.3 2.5 4.8 4.8l.1.1.4.3.3-.3.1-.1z" /></svg>
									</div> 
								</div>
							</a>
						</div>
					</div>
					<div className="widget widget_ascella_core_woo_side_area_cart" data-area="home"> 
						<div className="qodef-widget-side-area-cart-inner">
							<a itemProp="url" className="qodef-m-opener" href="#">
								<span className="qodef-m-opener-text">Cart</span>
								<span className="qodef-m-opener-icon">
								<svg viewBox="0 0 19.2 16.4" className="qodef-svg--cart">
								<path d="M15.8 16.4H3.5L0 5.8h19.2l-3.4 10.6zm-11.9-.6h11.4l3.1-9.5H.8l3.1 9.5zM6.4 0l.5.2-2.4 6-.5-.3L6.4 0zm6.4 0l2.4 5.9-.5.2L12.3.2l.5-.2z"></path>
								</svg>   
								</span>
								<span className="qodef-m-opener-count">0</span>
							</a>
							<div className="qodef-widget-side-area-cart-content">
								<h5 className="qodef-e-title">Shopping Bag</h5>
								<p className="qodef-m-posts-not-found">No products in the cart.</p>
								<a className="qodef-m-close" href="#">
								<span className="qodef-m-close-icon">
									<svg
									width="10"
									height="10"
									x="0"
									y="0"
									enableBackground="new 0 0 15.7 15.7"
									viewBox="0 0 15.7 15.7"
									className="qodef-svg--close"
									>
									<path d="M15.4.4l-15 15M.4.4l15 15"></path>
									</svg>
								</span>
								</a>
							</div>
						</div>
					</div> 
				</div>
			</div> */}
			<div className="qodef-header-sticky qodef-custom-header-layout qodef-sticky-menu-position--left qodef-appearance--down">
				<div className="qodef-header-sticky-inner ">
					<a itemProp="url" className="qodef-header-logo-link qodef-height--set qodef-source--image" href="/" rel="home">
						<img width="227" height="52" src={logo} className="qodef-header-logo-image qodef--main" alt="logo main" itemProp="image" />
					</a>
					<nav className="qodef-header-navigation" role="navigation" aria-label="Top Menu">
						<ul id="menu-main-menu-2" className="menu">
							<li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-30 qodef-menu-item--narrow">
								<a href="#"><span className="qodef-menu-item-text">Home
									<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
									</span>
								</a>
								<div className="qodef-drop-down-second">
									<div className="qodef-drop-down-second-inner">
										<ul className="sub-menu">
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-5924"><a href="#"><span className="qodef-menu-item-text">Main Home</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-336 current_page_item menu-item-1717"><a href="index.html"><span className="qodef-menu-item-text">Jewelry Store</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5921"><a href="#"><span className="qodef-menu-item-text">Parallax Home</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5922"><a href="#"><span className="qodef-menu-item-text">Jewelry Grid</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5923"><a href="#"><span className="qodef-menu-item-text">Jewelry Showcase</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1718"><a href="#"><span className="qodef-menu-item-text">Shop Minimal</span></a></li>
										<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3104"><a href="#"><span className="qodef-menu-item-text">Landing</span></a></li>
										</ul>
									</div>
								</div>
							</li>
							<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-31 qodef-menu-item--narrow">
								<a href="#"><span className="qodef-menu-item-text">Pages
									<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path>
									</svg>
									</span>
								</a>
								<div className="qodef-drop-down-second">
									<div className="qodef-drop-down-second-inner">
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2999"><a href="#"><span className="qodef-menu-item-text">About Me</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2998"><a href="#"><span className="qodef-menu-item-text">About Us</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3544"><a href="#"><span className="qodef-menu-item-text">Size Guide</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3491"><a href="#"><span className="qodef-menu-item-text">Gift Card</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4468"><a href="#"><span className="qodef-menu-item-text">Our Clients</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2997"><a href="#"><span className="qodef-menu-item-text">Get In Touch</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3060"><a href="#"><span className="qodef-menu-item-text">Contact Us</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3620"><a href="#"><span className="qodef-menu-item-text">FAQ Page</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3659"><a href="#"><span className="qodef-menu-item-text">Coming Soon</span></a></li>
										</ul>
									</div>
								</div>
							</li>
							<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-32 qodef-menu-item--wide"><a href="#"><span className="qodef-menu-item-text">Shop<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span></a>
								<div className="qodef-drop-down-second">
									<div className="qodef-drop-down-second-inner qodef-content-grid">
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1389 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Shop Lists<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span></a>
											<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1390"><a href="#"><span className="qodef-menu-item-text">Shop Standard</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2249"><a href="#"><span className="qodef-menu-item-text">Shop Slider</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1462"><a title="New" href="#"><span className="qodef-menu-item-text">Shop Wide List</span><span className="qodef-link-label">New</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1461"><a title="New" href="#"><span className="qodef-menu-item-text">Shop List With Baner</span><span className="qodef-link-label">New</span></a></li>
											</ul>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2251 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Filter Types<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span></a>
												<ul className="sub-menu">
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1460"><a href="#"><span className="qodef-menu-item-text">Shop Category Images</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1459"><a title="New" href="#"><span className="qodef-menu-item-text">Shop Side Filter</span><span className="qodef-link-label">New</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2243"><a href="#"><span className="qodef-menu-item-text">Standard Category Filter</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1515"><a title="New" href="#"><span className="qodef-menu-item-text">Shop The Look</span><span className="qodef-link-label">New</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2332"><a title="New" href="#"><span className="qodef-menu-item-text">Shop By Collection</span><span className="qodef-link-label">New</span></a></li>
												</ul>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-3409 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Shop Layouts<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span></a>
												<ul className="sub-menu">
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3471"><a href="#"><span className="qodef-menu-item-text">Two Columns</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3470"><a href="#"><span className="qodef-menu-item-text">Three Columns</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3469"><a href="#"><span className="qodef-menu-item-text">Three Columns Wide</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3467"><a href="#"><span className="qodef-menu-item-text">Four Columns</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3468"><a href="#"><span className="qodef-menu-item-text">Four Columns Wide</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3466"><a href="#"><span className="qodef-menu-item-text">Five Columns Wide</span></a></li>
												</ul>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-107 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Shop Pages<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span></a>
												<ul className="sub-menu">
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-109"><a href="#"><span className="qodef-menu-item-text">My Account</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-108"><a href="#"><span className="qodef-menu-item-text">Wishlist</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-111"><a href="#"><span className="qodef-menu-item-text">Cart</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-110"><a href="#"><span className="qodef-menu-item-text">Checkout</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3195"><a title="New" href="#"><span className="qodef-menu-item-text">Order Tracking</span><span className="qodef-link-label">New</span></a></li>
												</ul>
											</li>
										</ul>
									</div>
								</div>
							</li>
							<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-134 qodef-menu-item--wide">
								<a href="#">
									<span className="qodef-menu-item-text">Products
									<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
									<path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path>
									</svg>
									</span>
								</a> 
								<div className="qodef-drop-down-second">
									<div className="qodef-drop-down-second-inner qodef-content-grid qodef-content-grid">
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-138 qodef--hide-link">
												<a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Product Types
												<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg></span></a>
												<ul className="sub-menu">
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-4973"><a href="../product/simple-ring/index.html"><span className="qodef-menu-item-text">Standard Product</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-5048"><a href="../product/circle-ring/index.html"><span className="qodef-menu-item-text">Variable Product</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-4993"><a href="../product/pendant-flower/index.html"><span className="qodef-menu-item-text">Variable Product Colors</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-296"><a href="../product/necklaces/index.html"><span className="qodef-menu-item-text">Grouped Product</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-5427"><a href="../product/premium-card/index.html"><span className="qodef-menu-item-text">Virtual Product</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-620"><a title="New" href="../product/flower-ring/index.html"><span className="qodef-menu-item-text">Slider Custom</span><span className="qodef-link-label">New</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-314"><a href="../product/pearl-necklaces/index.html"><span className="qodef-menu-item-text">Big Images Product</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-295"><a title="New" href="../product/romoids/index.html"><span className="qodef-menu-item-text">Full Width Product</span><span className="qodef-link-label">New</span></a></li>
												</ul>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-135 qodef--hide-link">
												<a href="#" onClick={handleClick}>
													<span className="qodef-menu-item-text">Product Widgets
													<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
													</span>
												</a>
												<ul className="sub-menu">
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-2227"><a href="../product/diamonds-ring/index.html"><span className="qodef-menu-item-text">Video Products</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-3163"><a href="../product/nouvates-earrings/index.html"><span className="qodef-menu-item-text">Sale Product</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-3164"><a href="../product/spiral-ring/index.html"><span className="qodef-menu-item-text">New Product</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-3709"><a href="../product/woo-earrings/index.html"><span className="qodef-menu-item-text">Sold Product</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-136"><a title="New" href="../product/small-earrings/index.html"><span className="qodef-menu-item-text">Side Info Product</span><span className="qodef-link-label">New</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-5010"><a title="New" href="../product/silver-pearl/index.html"><span className="qodef-menu-item-text">Countdown Product</span><span className="qodef-link-label">New</span></a></li>
												</ul>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3187 qodef--hide-link qodef-mega-menu-widget--enabled"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Editors New Picks</span></a>
												<div className="qodef-mega-menu-widget-holder">
													<div className="widget widget_block" data-area="heder-wide">
														<div className="wp-block-columns is-layout-flex wp-container-10">
															<div className="wp-block-column is-layout-flow">
																<figure className="wp-block-image size-full is-resized">
																	<a href="#">
																		<img decoding="async" loading="lazy" src={menuimg2} alt="" className="wp-image-3407" width="308" height="234" srcSet={menuimg2} sizes="(max-width: 308px) 100vw, 308px" />
																	</a>
																</figure>
																<p style={{color: "#000000",margin: "-11px 0 5px 0"}}>Introducting New Collection</p>
																<div className="widget widget_ascella_core_button">
																	<a className="qodef-shortcode qodef-m  qodef-button qodef-layout--textual  qodef-html--link" href="#" target="_self" style={{color: "#000000"}}> <span className="qodef-m-text">Shop Now</span></a>
																</div>
															</div>
															<div className="wp-block-column is-layout-flow">
																<figure className="wp-block-gallery has-nested-images columns-default is-cropped wp-block-gallery-7 is-layout-flex">
																	<figure className="wp-block-image size-full is-style-default"><a href="#"><img decoding="async" loading="lazy" width="308" height="235" src={menuimg1} alt="" className="wp-image-5213" srcSet={menuimg1} /></a></figure>
																</figure>
															</div>
														</div>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</li>
							<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-33 qodef--hide-link qodef-menu-item--narrow"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Portfolio
								<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
								</span></a>
								<div className="qodef-drop-down-second">
									<div className="qodef-drop-down-second-inner">
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2466"><a href="#"><span className="qodef-menu-item-text">Gallery List</span></a></li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2451 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Single Types
												<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
												</span></a>
												<ul className="sub-menu">
													<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2456"><a href="#"><span className="qodef-menu-item-text">Small Images</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2457"><a href="#"><span className="qodef-menu-item-text">Big Images</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2454"><a href="#"><span className="qodef-menu-item-text">Small Slider</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2455"><a href="#"><span className="qodef-menu-item-text">Big Slider</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2453"><a href="#"><span className="qodef-menu-item-text">Small Gallery</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2452"><a href="#"><span className="qodef-menu-item-text">Big Gallery</span></a></li>
												</ul>
											</li>
										</ul>
									</div>
								</div>
							</li>
							<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-34 qodef--hide-link qodef-menu-item--narrow"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Blog
								<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
								</span></a>
								<div className="qodef-drop-down-second">
									<div className="qodef-drop-down-second-inner">
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3924"><a href="#"><span className="qodef-menu-item-text">Right Sidebar</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3923"><a href="#"><span className="qodef-menu-item-text">Left Sidebar</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3922"><a href="#"><span className="qodef-menu-item-text">No Sidebar</span></a></li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2475 qodef--hide-link"><a href="#" onClick={handleClick}>
												<span className="qodef-menu-item-text">Post Formats<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
												</span></a>
												<ul className="sub-menu">
													<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2474"><a href="#"><span className="qodef-menu-item-text">Standard</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2471"><a href="#"><span className="qodef-menu-item-text">Gallery</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2470"><a href="#"><span className="qodef-menu-item-text">Link</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2469"><a href="#"><span className="qodef-menu-item-text">Quote</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2467"><a href="#"><span className="qodef-menu-item-text">Audio</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2468"><a href="#"><span className="qodef-menu-item-text">Video</span></a></li>
													<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-3925"><a href="#"><span className="qodef-menu-item-text">No Sidebar</span></a></li>
												</ul>
											</li>
										</ul>
									</div>
								</div>
							</li>
						</ul> 
					</nav>
					<div className="qodef-widget-holder qodef--one">
						<div className="widget widget_ascella_membership_login_opener" data-area="home"> 
							<div className="qodef-login-opener-widget qodef-user-logged--out">
								<a href="#" className="qodef-login-opener">
									<span className="qodef-login-opener-text">
									<svg enableBackground="new 0 0 15.4 15.4" viewBox="0 0 15.4 15.4" className="qodef-svg--account">
									<path d="M3 10.5c1.6-.7 3.2-1 4.7-1s3 .3 4.7 1c1.6.7 2.4 1.5 2.4 2.6v1.8H.6v-1.8c0-1.1.8-2 2.4-2.6zm7.2-3.8c-.7.7-1.5 1-2.5 1s-1.8-.3-2.5-1-1-1.5-1-2.5.3-1.8 1-2.5 1.5-1 2.5-1 1.8.3 2.5 1 1 1.5 1 2.5c.1 1-.3 1.8-1 2.5z"></path>
									</svg>
									</span>
								</a>
							</div>
						</div>
						<div className="widget widget_ascella_core_svg_icon" data-area="home"> 
							<div className="qodef-svg-icon-widget">
								<a href="#" target="_self">
									<div className="qodef-m-holder" style={{alignitems:"center"}}>
										<div className="qodef-m-icon" style={{margin: "0 10px 0 8px",strokecolor: "#ffffff00",strokehovercolor: "#ffffff00"}}>
											<svg xlinkHref="http://www.w3.org/2000/svg" viewBox="0 0 16.3 15" width="16"><path d="M11.8 0C13 0 14.1.4 15 1.3s1.3 1.9 1.3 3.2c0 .8-.1 1.5-.4 2.2-.3.7-.9 1.4-1.6 2.3-.8.9-1.5 1.6-2.1 2.2-.6.6-1.6 1.5-2.9 2.7L8.1 15 7 13.9c-1.4-1.3-2.3-2.2-2.9-2.7C3.4 10.6 2.7 9.9 2 9 1.2 8.1.7 7.4.4 6.7.1 6 0 5.2 0 4.5c0-1.3.4-2.3 1.3-3.2S3.2 0 4.5 0C6 0 7.2.6 8.2 1.7 9.1.6 10.3 0 11.8 0zM8.6 12.9c2.5-2.3 4.1-3.9 4.8-4.8 1-1.3 1.6-2.5 1.6-3.6 0-.9-.3-1.7-.9-2.3s-1.4-.9-2.3-.9c-1.1 0-2 .4-2.7 1.3l-1 1.1-.9-1.2c-.7-.8-1.7-1.2-2.7-1.2-.9 0-1.6.3-2.3.9s-.9 1.4-.9 2.3c0 1.1.5 2.3 1.6 3.6.7.9 2.3 2.5 4.8 4.8l.1.1.4.3.3-.3.1-.1z" /></svg> 
										</div>
									</div>
								</a>
							</div>
						</div>
						<div className="widget widget_ascella_core_woo_side_area_cart" data-area="home"> 
							<div className="qodef-widget-side-area-cart-inner">
								<a itemProp="url" className="qodef-m-opener" href="#">
									<span className="qodef-m-opener-text">Cart</span>
									<span className="qodef-m-opener-icon">
									<svg  className="qodef-svg--cart"  viewBox="0 0 19.2 16.4">
									<path d="M15.8 16.4H3.5L0 5.8h19.2l-3.4 10.6zm-11.9-.6h11.4l3.1-9.5H.8l3.1 9.5zM6.4 0l.5.2-2.4 6-.5-.3L6.4 0zm6.4 0l2.4 5.9-.5.2L12.3.2l.5-.2z"></path>
									</svg>
									</span>
									<span className="qodef-m-opener-count">0</span>
								</a>
								<div className="qodef-widget-side-area-cart-content">
									<h5 className="qodef-e-title">Shopping Bag</h5>
									<p className="qodef-m-posts-not-found">No products in the cart.</p>
									<a className="qodef-m-close" href="#">
										<span className="qodef-m-close-icon">
										<svg
										width="10"
										height="10"
										x="0"
										y="0"
										enableBackground="new 0 0 15.7 15.7"
										viewBox="0 0 15.7 15.7"
										className="qodef-svg--close"
										>
										<path d="M15.4.4l-15 15M.4.4l15 15"></path>
										</svg></span>
									</a>
								</div>
							</div>
						</div> 
					</div>
				</div>
			</div>
		</header>
		<header id="qodef-page-mobile-header" role="banner">
			<div id="qodef-page-mobile-header-inner" className="">
				<a itemProp="url" className="qodef-mobile-header-logo-link qodef-height--set qodef-source--image" href="/" rel="home">
				<img width="172" height="40" src={logo} className="qodef-header-logo-image qodef--main" alt="logo main" itemProp="image" /></a>
				<div className="qodef-widget-holder qodef--one">
					<div id="ascella_core_woo_side_area_cart-5" className="widget widget_ascella_core_woo_side_area_cart qodef-mobile-header-widget-area-one" data-area="mobile-header"> 
						<div className="qodef-widget-side-area-cart-inner">
							<a itemProp="url" className="qodef-m-opener" href="#">
							<span className="qodef-m-opener-text">Cart</span>
							<span className="qodef-m-opener-icon">
							<svg viewBox="0 0 19.2 16.4" className="qodef-svg--cart">
							<path d="M15.8 16.4H3.5L0 5.8h19.2l-3.4 10.6zm-11.9-.6h11.4l3.1-9.5H.8l3.1 9.5zM6.4 0l.5.2-2.4 6-.5-.3L6.4 0zm6.4 0l2.4 5.9-.5.2L12.3.2l.5-.2z"></path>
							</svg></span>
							<span className="qodef-m-opener-count">0</span>
							</a>
							<div className="qodef-widget-side-area-cart-content">
								<h5 className="qodef-e-title">Shopping Bag</h5>
								<p className="qodef-m-posts-not-found">No products in the cart.</p>
								<a className="qodef-m-close" href="#">
								<span className="qodef-m-close-icon"> <svg
								width="10"
								height="10"
								x="0"
								y="0"
								enableBackground="new 0 0 15.7 15.7"
								viewBox="0 0 15.7 15.7"
								className="qodef-svg--close" 
								>
								<path d="M15.4.4l-15 15M.4.4l15 15"></path>
								</svg></span>
								</a>
							</div>
						</div>
					</div> 
				</div>
				<a href="#" className="qodef-opener-icon qodef-m qodef-source--predefined qodef-mobile-header-opener">
				<span className="qodef-m-icon qodef--open">
				<span className="qodef-m-lines"><span className="qodef-m-line qodef--1"></span><span className="qodef-m-line qodef--2"></span><span className="qodef-m-line qodef--3"></span></span> </span>
				<span className="qodef-m-icon qodef--close">
				<span className="qodef-m-lines"><span className="qodef-m-line qodef--1"></span><span className="qodef-m-line qodef--2"></span><span className="qodef-m-line qodef--3"></span></span> </span>
				</a>
			</div>
			<nav className="qodef-mobile-header-navigation" role="navigation" aria-label="Mobile Menu">
				<ul id="menu-main-menu-4" className="">
					<li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-30 qodef-menu-item--narrow"><a href="#"><span className="qodef-menu-item-text">Home</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
						<div className="qodef-drop-down-second">
							<div className="qodef-drop-down-second-inner">
								<ul className="sub-menu">
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-5924"><a href="/"><span className="qodef-menu-item-text">Main Home</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-336 current_page_item menu-item-1717"><a href="/"><span className="qodef-menu-item-text">Jewelry Store</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5921"><a href="#"><span className="qodef-menu-item-text">Parallax Home</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5922"><a href="#"><span className="qodef-menu-item-text">Jewelry Grid</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5923"><a href="#"><span className="qodef-menu-item-text">Jewelry Showcase</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1718"><a href="#"><span className="qodef-menu-item-text">Shop Minimal</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3104"><a href="#"><span className="qodef-menu-item-text">Landing</span></a></li>
								</ul>
							</div>
						</div>
					</li>
					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-31 qodef-menu-item--narrow"><a href="#"><span className="qodef-menu-item-text">Pages</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
						<div className="qodef-drop-down-second">
							<div className="qodef-drop-down-second-inner">
								<ul className="sub-menu">
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2999"><a href="#"><span className="qodef-menu-item-text">About Me</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2998"><a href="#"><span className="qodef-menu-item-text">About Us</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3544"><a href="#"><span className="qodef-menu-item-text">Size Guide</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3491"><a href="#"><span className="qodef-menu-item-text">Gift Card</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4468"><a href="#"><span className="qodef-menu-item-text">Our Clients</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2997"><a href="#"><span className="qodef-menu-item-text">Get In Touch</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3060"><a href="#"><span className="qodef-menu-item-text">Contact Us</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3620"><a href="#"><span className="qodef-menu-item-text">FAQ Page</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3659"><a href="#"><span className="qodef-menu-item-text">Coming Soon</span></a></li>
								</ul>
							</div>
						</div>
					</li>
					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-32 qodef-menu-item--wide"><a href="#"><span className="qodef-menu-item-text">Shop</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
						<div className="qodef-drop-down-second">
							<div className="qodef-drop-down-second-inner qodef-content-grid">
								<ul className="sub-menu">
									<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1389 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Shop Lists</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1390"><a href="#"><span className="qodef-menu-item-text">Shop Standard</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2249"><a href="#"><span className="qodef-menu-item-text">Shop Slider</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1462"><a title="New" href="#"><span className="qodef-menu-item-text">Shop Wide List</span><span className="qodef-link-label">New</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1461"><a title="New" href="#"><span className="qodef-menu-item-text">Shop List With Baner</span><span className="qodef-link-label">New</span></a></li>
										</ul>
									</li>
									<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2251 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Filter Types</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1460"><a href="#"><span className="qodef-menu-item-text">Shop Category Images</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1459"><a title="New" href="#"><span className="qodef-menu-item-text">Shop Side Filter</span><span className="qodef-link-label">New</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2243"><a href="#"><span className="qodef-menu-item-text">Standard Category Filter</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1515"><a title="New" href="#"><span className="qodef-menu-item-text">Shop The Look</span><span className="qodef-link-label">New</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2332"><a title="New" href="#"><span className="qodef-menu-item-text">Shop By Collection</span><span className="qodef-link-label">New</span></a></li>
										</ul>
									</li>
									<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-3409 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Shop Layouts</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3471"><a href="#"><span className="qodef-menu-item-text">Two Columns</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3470"><a href="#"><span className="qodef-menu-item-text">Three Columns</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3469"><a href="#"><span className="qodef-menu-item-text">Three Columns Wide</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3467"><a href="#"><span className="qodef-menu-item-text">Four Columns</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3468"><a href="#"><span className="qodef-menu-item-text">Four Columns Wide</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3466"><a href="#"><span className="qodef-menu-item-text">Five Columns Wide</span></a></li>
										</ul>
									</li>
									<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-107 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Shop Pages</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-109"><a href="#"><span className="qodef-menu-item-text">My Account</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-108"><a href="#"><span className="qodef-menu-item-text">Wishlist</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-111"><a href="#"><span className="qodef-menu-item-text">Cart</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-110"><a href="#"><span className="qodef-menu-item-text">Checkout</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3195"><a title="New" href="#"><span className="qodef-menu-item-text">Order Tracking</span><span className="qodef-link-label">New</span></a></li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</li>
					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-134 qodef-menu-item--wide"><a href="#"><span className="qodef-menu-item-text">Products</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
						<div className="qodef-drop-down-second">
							<div className="qodef-drop-down-second-inner qodef-content-grid qodef-content-grid">
								<ul className="sub-menu">
									<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-138 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Product Types</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-4973"><a href="#"><span className="qodef-menu-item-text">Standard Product</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-5048"><a href="#"><span className="qodef-menu-item-text">Variable Product</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-4993"><a href="#"><span className="qodef-menu-item-text">Variable Product Colors</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-296"><a href="#"><span className="qodef-menu-item-text">Grouped Product</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-5427"><a href="#"><span className="qodef-menu-item-text">Virtual Product</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-620"><a title="New" href="#"><span className="qodef-menu-item-text">Slider Custom</span><span className="qodef-link-label">New</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-314"><a href="#"><span className="qodef-menu-item-text">Big Images Product</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-295"><a title="New" href="#"><span className="qodef-menu-item-text">Full Width Product</span><span className="qodef-link-label">New</span></a></li>
										</ul>
									</li>
									<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-135 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Product Widgets</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-2227"><a href="#"><span className="qodef-menu-item-text">Video Products</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-3163"><a href="#"><span className="qodef-menu-item-text">Sale Product</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-3164"><a href="#"><span className="qodef-menu-item-text">New Product</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-3709"><a href="#"><span className="qodef-menu-item-text">Sold Product</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-136"><a title="New" href="#"><span className="qodef-menu-item-text">Side Info Product</span><span className="qodef-link-label">New</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-product menu-item-5010"><a title="New" href="#"><span className="qodef-menu-item-text">Countdown Product</span><span className="qodef-link-label">New</span></a></li>
										</ul>
									</li>
									<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3187 qodef--hide-link qodef-mega-menu-widget--enabled"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Editors New Picks</span></a>
										<div className="qodef-mega-menu-widget-holder">
											<div className="widget widget_block" data-area="heder-wide">
												<div className="wp-block-columns is-layout-flex wp-container-15">
												<div className="wp-block-column is-layout-flow">
													<figure className="wp-block-image size-full is-resized"><a href="#"><img decoding="async" loading="lazy" src={menuimg2} alt="" className="wp-image-3407" width="308" height="234" srcSet={menuimg2} sizes="(max-width: 308px) 100vw, 308px" /></a></figure>
													<p style={{color: "#000000", margin: "-11px 0 5px 0"}}>Introducting New Collection</p>
													<div className="widget widget_ascella_core_button"><a className="qodef-shortcode qodef-m  qodef-button qodef-layout--textual  qodef-html--link" href="#" target="_self" style={{color: "#000000"}}> <span className="qodef-m-text">Shop Now</span></a>
													</div>
												</div>
												<div className="wp-block-column is-layout-flow">
													<figure className="wp-block-gallery has-nested-images columns-default is-cropped wp-block-gallery-12 is-layout-flex">
													<figure className="wp-block-image size-full is-style-default"><a href="#"><img decoding="async" loading="lazy" width="308" height="235" src={menuimg1} alt="" className="wp-image-5213" srcSet={menuimg1} sizes="(max-width: 308px) 100vw, 308px" /></a></figure>
													</figure>
												</div>
											</div>
										</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</li>
					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-33 qodef--hide-link qodef-menu-item--narrow"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Portfolio</span></a>
						<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
						<div className="qodef-drop-down-second">
							<div className="qodef-drop-down-second-inner">
								<ul className="sub-menu">
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2466"><a href="#"><span className="qodef-menu-item-text">Gallery List</span></a></li>
									<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2451 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Single Types</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2456"><a href="#"><span className="qodef-menu-item-text">Small Images</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2457"><a href="#"><span className="qodef-menu-item-text">Big Images</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2454"><a href="#"><span className="qodef-menu-item-text">Small Slider</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2455"><a href="#"><span className="qodef-menu-item-text">Big Slider</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2453"><a href="#"><span className="qodef-menu-item-text">Small Gallery</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-portfolio-item menu-item-2452"><a href="#"><span className="qodef-menu-item-text">Big Gallery</span></a></li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</li>
					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-34 qodef--hide-link qodef-menu-item--narrow"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Blog</span></a><svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
						<div className="qodef-drop-down-second">
							<div className="qodef-drop-down-second-inner">
								<ul className="sub-menu">
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3924"><a href="#"><span className="qodef-menu-item-text">Right Sidebar</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3923"><a href="#"><span className="qodef-menu-item-text">Left Sidebar</span></a></li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3922"><a href="#"><span className="qodef-menu-item-text">No Sidebar</span></a></li>
									<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-2475 qodef--hide-link"><a href="#" onClick={handleClick}><span className="qodef-menu-item-text">Post Formats</span></a>
										<svg className="qodef-svg--menu-arrow qodef-menu-item-arrow" xlinkHref="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32"><path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path></svg>
										<ul className="sub-menu">
											<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2474"><a href="#"><span className="qodef-menu-item-text">Standard</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2471"><a href="#"><span className="qodef-menu-item-text">Gallery</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2470"><a href="#"><span className="qodef-menu-item-text">Link</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2469"><a href="#"><span className="qodef-menu-item-text">Quote</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2467"><a href="#"><span className="qodef-menu-item-text">Audio</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2468"><a href="#"><span className="qodef-menu-item-text">Video</span></a></li>
											<li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-3925"><a href="#"><span className="qodef-menu-item-text">No Sidebar</span></a></li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</li>
				</ul> 
			</nav>
		</header>
	</div>
  )
}
export default HeaderMenu;
