import React from 'react';

const About = () => {
  return (
   <div style={{paddingTop:"100px"}}>
    About us
   </div>
  )
}
export default About;
