import React, { useEffect } from 'react';
// import detailimg1 from './assets/img/uploads/2022/12/h2-product-sl-2-img-1.jpg';
// import detailimg2 from './assets/img/uploads/2022/12/h2-product-sl-2-img-2.jpg';
// import detailimg3 from './assets/img/uploads/2022/12/h2-product-sl-2-img-3.jpg';
// import detailimg4 from './assets/img/uploads/2022/12/h2-product-sl-2-img-4.jpg';
// import detailimg5 from './assets/img/uploads/2022/12/h2-product-sl-2-img-5.jpg';
// import detailimg6 from './assets/img/uploads/2022/12/h2-product-sl-2-img-6.jpg';
// import dataimg1 from './assets/img/uploads/2022/12/h2-product-sl-2-img-1-480x643.jpg';
// import dataimg2 from './assets/img/uploads/2022/12/h2-product-sl-2-img-2-480x592.jpg';
// import dataimg3 from './assets/img/uploads/2022/12/h2-product-sl-2-img-3-480x592.jpg';
// import dataimg4 from './assets/img/uploads/2022/12/h2-product-sl-2-img-4-480x592.jpg';
// import dataimg5 from './assets/img/uploads/2022/12/h2-product-sl-2-img-5-480x592.jpg';
// import dataimg6 from './assets/img/uploads/2022/12/h2-product-sl-2-img-6-480x592.jpg';
import { useParams } from "react-router-dom";
import { useProductContext } from "./context/ProductContext";
import PageNavigation from "./component/PageNavigation";
import FormatPrice from "./Helpers/FormatPrice";  
import ImageSliderDetail from './component/ImageSliderDetail';

const API = "https://api.pujakaitem.com/api/products";

const ProductDetail = () => {
    const { getSingleProduct, isSingleLoading, singleProduct } = useProductContext();
    const { id } = useParams();

    const {
        id: alias,
        name,
        company,
        price,
        description,
        category,
        stock,
        stars,
        reviews,
        image,
    } = singleProduct;

    useEffect(() => {
        getSingleProduct(`${API}?id=${id}`);
      }, []);

    //   if (isSingleLoading) {
    //     return <div className="page_loading">Loading.....</div>;
    //   }
          
  return (
    <div id="qodef-page-outer">
        <PageNavigation title={name} />
        <div id="qodef-page-inner" className="qodef-content-grid qodef-content--in-grid">
            <main id="qodef-page-content" className="qodef-grid qodef-layout--template qodef-grid-template--12 " role="main">
                <div className="qodef-grid-inner">
                    <div id="qodef-woo-page" className="qodef-grid-item qodef-col--12 qodef--single qodef-popup--magnific-popup qodef-magnific-popup qodef-popup-gallery">
                        <div className="woocommerce-notices-wrapper"></div>
                        <div id="product-2708" className="product type-product post-2708 status-publish first instock product_cat-golden-line product_cat-hoops product_tag-gold product_tag-jewerly has-post-thumbnail shipping-taxable purchasable product-type-simple">
                            <div className="qodef-woo-single-inner">
                               <ImageSliderDetail />
                                <div className="summary entry-summary">
                                    <h1 className="qodef-woo-product-title product_title entry-title">{name}</h1>
                                    <p className="price"><span className="woocs_price_code" data-currency="" data-redraw-id="647832b57009f" data-product-id="2708"><span className="woocommerce-Price-amount amount"><bdi><FormatPrice price={price} /></bdi></span></span></p>
                                    <div className="woocommerce-product-details__short-description">
                                    <p>{description}</p>
                                    </div>
                                    <form className="cart" action="#" method="post" encType='multipart/form-data'>
                                        <div className="qodef-quantity-buttons quantity">
                                            <label className="screen-reader-text" htmlFor="quantity_647832b570ae8">Heart Hoops quantity</label>
                                            <span className="qodef-quantity-minus"></span>
                                            <input type="text" id="quantity_647832b570ae8" className="input-text qty text qodef-quantity-input" value="1" readOnly name="quantity"  title="Qty" size="4" data-min="1" data-max="" data-step="1" placeholder="" inputMode="numeric" autoComplete="off" />
                                            <span className="qodef-quantity-plus"></span>
                                        </div>
                                        <button type="submit" name="add-to-cart"  className="single_add_to_cart_button button alt wp-element-button">Add to cart</button>
                                    </form>
                                    <div className="yith-wcwl-add-to-wishlist add-to-wishlist-2708  wishlist-fragment on-first-load" data-fragment-ref="2708" data-fragment-options="{&quot;base_url&quot;:&quot;&quot;,&quot;in_default_wishlist&quot;:false,&quot;is_single&quot;:true,&quot;show_exists&quot;:false,&quot;product_id&quot;:2708,&quot;parent_product_id&quot;:2708,&quot;product_type&quot;:&quot;simple&quot;,&quot;show_view&quot;:true,&quot;browse_wishlist_text&quot;:&quot;Browse wishlist&quot;,&quot;already_in_wishslist_text&quot;:&quot;The product is already in your wishlist!&quot;,&quot;product_added_text&quot;:&quot;Product added!&quot;,&quot;heading_icon&quot;:&quot;fa-heart-o&quot;,&quot;available_multi_wishlist&quot;:false,&quot;disable_wishlist&quot;:false,&quot;show_count&quot;:false,&quot;ajax_loading&quot;:false,&quot;loop_position&quot;:&quot;after_add_to_cart&quot;,&quot;item&quot;:&quot;add_to_wishlist&quot;}">
                                        <div className="yith-wcwl-add-button">
                                            <a href="indexc10a.html?add_to_wishlist=2708&amp;_wpnonce=b57af069ef" className="add_to_wishlist single_add_to_wishlist" data-product-id="2708" data-product-type="simple" data-original-product-id="2708" data-title="Add to wishlist" rel="nofollow">
                                                <i className="yith-wcwl-icon fa fa-heart-o"></i> <span>Add to wishlist</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="qodef-shortcode qodef-m  qodef-social-share clear qodef-layout--dropdown qodef-dropdown--right"> 
                                        <a className="qodef-social-share-dropdown-opener" href="#"> 
                                            <span className="qodef-social-title qodef-custom-label">Share:</span> 
                                            <span className="qodef-e-social-share-icon"> 
                                                <svg className="qodef-svg--social-share" xmlns="http://www.w3.org/2000/svg" width="10.25" height="11.266" viewBox="0 0 10.25 11.266">
                                                    <path d="M7.059-2.234a1.442,1.442,0,0,1,1.066-.406,1.52,1.52,0,0,1,1.117.457A1.52,1.52,0,0,1,9.7-1.066,1.52,1.52,0,0,1,9.242.051,1.52,1.52,0,0,1,8.125.508,1.52,1.52,0,0,1,7.008.051a1.52,1.52,0,0,1-.457-1.117A1.492,1.492,0,0,1,6.6-1.422L2.742-3.656a1.625,1.625,0,0,1-1.117.432A1.562,1.562,0,0,1,.482-3.707,1.562,1.562,0,0,1,0-4.85,1.583,1.583,0,0,1,.47-5.992,1.52,1.52,0,0,1,1.6-6.475a1.625,1.625,0,0,1,1.117.432L6.551-8.252A2.139,2.139,0,0,1,6.5-8.633a1.562,1.562,0,0,1,.482-1.143,1.562,1.562,0,0,1,1.143-.482,1.562,1.562,0,0,1,1.143.482A1.562,1.562,0,0,1,9.75-8.633,1.562,1.562,0,0,1,9.268-7.49a1.562,1.562,0,0,1-1.143.482,1.625,1.625,0,0,1-1.117-.432L3.174-5.23a2.139,2.139,0,0,1,.051.381,2.139,2.139,0,0,1-.051.381Z" transform="translate(0.25 10.508)" />
                                                </svg> 
                                            </span> 
                                        </a> 
                                        <div className="qodef-social-share-dropdown"> 
                                            <ul className="qodef-shortcode-list"> 
                                                <li className="qodef-facebook-share"> 
                                                    <a itemProp="url" className="qodef-share-link" href="#"> 
                                                    <svg className="qodef-svg--facebook qodef-facebook-share" xmlns="http://www.w3.org/2000/svg" width="14.531" height="14.443" viewBox="0 0 14.531 14.443">
                                                        <path d="M14.766,7.375a7.02,7.02,0,0,1-1.758,4.731,7.056,7.056,0,0,1-4.365,2.446V9.484h1.7l.322-2.109H8.643V6q0-1.113,1.172-1.113h.908V3.1a9.155,9.155,0,0,0-1.611-.146,3.03,3.03,0,0,0-1.45.322,2.331,2.331,0,0,0-.952.967,3.135,3.135,0,0,0-.352,1.523V7.375H4.512V9.484H6.357v5.068a7.056,7.056,0,0,1-4.365-2.446A7.02,7.02,0,0,1,.234,7.375,7,7,0,0,1,2.358,2.233,7,7,0,0,1,7.5.109a7,7,0,0,1,5.142,2.124A7,7,0,0,1,14.766,7.375Z" transform="translate(-0.234 -0.109)" />
                                                    </svg> 
                                                    </a>
                                                </li>
                                                <li className="qodef-twitter-share"> 
                                                    <a itemProp="url" className="qodef-share-link" href="#" > 
                                                        <svg className="qodef-svg--twitter qodef-twitter-share" xmlns="http://www.w3.org/2000/svg" width="15" height="12.188" viewBox="0 0 15 12.188">
                                                         <path d="M13.447,4.328a1.745,1.745,0,0,1,.029.381A8.625,8.625,0,0,1,12.891,7.8a9.745,9.745,0,0,1-1.655,2.813,7.9,7.9,0,0,1-2.769,2.065,8.777,8.777,0,0,1-3.75.791A8.535,8.535,0,0,1,0,12.092q.322.029.732.029A6.1,6.1,0,0,0,4.57,10.8a2.862,2.862,0,0,1-1.8-.6A3.059,3.059,0,0,1,1.7,8.693q.293.029.557.029a3.905,3.905,0,0,0,.82-.088,2.989,2.989,0,0,1-1.26-.586A3.21,3.21,0,0,1,.938,6.979,2.948,2.948,0,0,1,.615,5.617V5.559a2.9,2.9,0,0,0,1.377.41A2.956,2.956,0,0,1,.615,3.391a2.97,2.97,0,0,1,.439-1.553,8.483,8.483,0,0,0,2.8,2.285,8.515,8.515,0,0,0,3.53.938,4.27,4.27,0,0,1-.059-.7,2.983,2.983,0,0,1,.894-2.168,2.911,2.911,0,0,1,2.153-.908,2.969,2.969,0,0,1,2.256.967,6.259,6.259,0,0,0,1.963-.732,3.053,3.053,0,0,1-1.348,1.7A6.608,6.608,0,0,0,15,2.717,6.762,6.762,0,0,1,13.447,4.328Z" transform="translate(0 -1.281)" /></svg> 
                                                    </a>
                                                </li>
                                                <li className="qodef-linkedin-share"> 
                                                    <a itemProp="url" className="qodef-share-link" href="#" > 
                                                    <svg className="qodef-svg--linkedin qodef-linkedin-share" xmlns="http://www.w3.org/2000/svg" width="13.125" height="13.125" viewBox="0 0 13.125 13.125"><path d="M2.93,13H.205V4.24H2.93ZM1.582,3.039A1.521,1.521,0,0,1,.469,2.57,1.521,1.521,0,0,1,0,1.457,1.521,1.521,0,0,1,.469.344,1.521,1.521,0,0,1,1.582-.125,1.593,1.593,0,0,1,2.944.651a1.534,1.534,0,0,1,0,1.582,1.683,1.683,0,0,1-.586.586A1.485,1.485,0,0,1,1.582,3.039ZM13.125,13H10.4V8.723q0-.5-.029-.806a3.775,3.775,0,0,0-.146-.7,1.052,1.052,0,0,0-.425-.6,1.428,1.428,0,0,0-.806-.205,1.35,1.35,0,0,0-1.3.615,3.419,3.419,0,0,0-.337,1.641V13H4.629V4.24H7.266v1.2h.029a2.572,2.572,0,0,1,.967-1.011,2.959,2.959,0,0,1,1.611-.425,3.914,3.914,0,0,1,1.655.308,2.149,2.149,0,0,1,1,.908,4.243,4.243,0,0,1,.469,1.3,9.186,9.186,0,0,1,.132,1.67Z" transform="translate(0 0.125)" /></svg> </a>
                                                </li>
                                            </ul> 
                                        </div>
                                    </div>
                                    <div className="product_meta">
                                        <span className="sku_wrapper">
                                            <span className="qodef-woo-meta-label">SKU:</span>
                                            <span className="sku qodef-woo-meta-value">113</span>
                                        </span>
                                        <span className="posted_in"><span className="qodef-woo-meta-label">Category:</span><span className="qodef-woo-meta-value"><a href="#" rel="tag">{category}</a></span></span>
                                        <span className="tagged_as"><span className="qodef-woo-meta-label">Available:</span><span className="qodef-woo-meta-value">{stock > 0 ? "In Stock" : "Not Available"}</span></span>
                                        <span className="tagged_as"><span className="qodef-woo-meta-label">Tags:</span><span className="qodef-woo-meta-value"><a href="#" rel="tag">Gold</a> <a href="#" rel="tag">Jewerly</a></span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="qodef-description--in-grid qodef-content-grid qodef-content-grid-1300">
                                <div className="woocommerce-tabs wc-tabs-wrapper">
                                    <ul className="tabs wc-tabs" role="tablist">
                                        <li className="description_tab" id="tab-title-description" role="tab" aria-controls="tab-description">
                                            <a href="#tab-description">Description </a>
                                        </li>
                                        <li className="additional_information_tab" id="tab-title-additional_information" role="tab" aria-controls="tab-additional_information">
                                            <a href="#tab-additional_information">Additional information </a>
                                        </li>
                                        <li className="reviews_tab" id="tab-title-reviews" role="tab" aria-controls="tab-reviews">
                                            <a href="#tab-reviews">Reviews (0) </a>
                                        </li>
                                    </ul>
                                    <div className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab" id="tab-description" role="tabpanel" aria-labelledby="tab-title-description">
                                        <h2>Description</h2>
                                        <div data-elementor-type="wp-post" data-elementor-id="2708" className="elementor elementor-2708">
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-9652403 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="9652403" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-default">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-799f63b" data-id="799f63b" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-eccf9d9 elementor-widget elementor-widget-ascella_core_section_title" data-id="eccf9d9" data-element_type="widget" data-widget_type="ascella_core_section_title.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="qodef-shortcode qodef-m  qodef-section-title qodef-alignment--left  ">
                                                                        <h4 className="qodef-m-title">About design </h4>
                                                                        <p className="qodef-m-text" style={{marginTop: "10px"}}>Lorem Ipsum estibulum blandit libero at mauris condimentum scelerisque. In scelerisque in mauris ut malesuada. Nam sodales scelerisque ipsum sed vestibulum. Aliquam euismod mi vitae nibh placerat.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-6b82e49 elementor-widget elementor-widget-text-editor" data-id="6b82e49" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container"></div>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ac50974" data-id="ac50974" data-element_type="column">
                                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                                <div className="elementor-element elementor-element-407f1b9 elementor-widget elementor-widget-ascella_core_section_title" data-id="407f1b9" data-element_type="widget" data-widget_type="ascella_core_section_title.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="qodef-shortcode qodef-m  qodef-section-title qodef-alignment--left  ">
                                                                            <h5 className="qodef-m-title">Summer collection </h5>
                                                                            <p className="qodef-m-text" style={{margintop: "10px"}}>Lorem Ipsum estibulum blandit libero at mauris condimentum syelerisque. In scelerisque in mauris ut malesuada. Nam sodales scelerisque ipsum sed vestib.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-d0b4322 elementor-widget elementor-widget-text-editor" data-id="d0b4322" data-element_type="widget" data-widget_type="text-editor.default">
                                                                    <div className="elementor-widget-container">
                                                                        <h6>Pearl intuition</h6> 
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-6a65874 elementor-widget elementor-widget-text-editor" data-id="6a65874" data-element_type="widget" data-widget_type="text-editor.default">
                                                                    <div className="elementor-widget-container">
                                                                        <p>Nam sodales scelerisque ipsum <span style={{textdecoration: "underline"}}>sed vestibulum</span> aliquam euismod mi vitae nibh.</p> 
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-193320c elementor-widget elementor-widget-text-editor" data-id="193320c" data-element_type="widget" data-widget_type="text-editor.default">
                                                                    <div className="elementor-widget-container">
                                                                        <h6>Serenity blue sandstone</h6> 
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-55ed69a elementor-widget elementor-widget-text-editor" data-id="55ed69a" data-element_type="widget" data-widget_type="text-editor.default">
                                                                    <div className="elementor-widget-container">
                                                                        <p>Ut venenatis iaculis iaculis. Integer sit <span style={{textdecoration: "underline"}}>amet auctor justo</span>, ac vestibulum orci. Aenean ultrices magna at porttitor eleifend.</p> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div className="woocommerce-Tabs-panel woocommerce-Tabs-panel--additional_information panel entry-content wc-tab" id="tab-additional_information" role="tabpanel" aria-labelledby="tab-title-additional_information">
                                        <h2>Additional information</h2>
                                        <table className="woocommerce-product-attributes shop_attributes">
                                            <tbody>
                                                <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--weight">
                                                    <th className="woocommerce-product-attributes-item__label">Weight</th>
                                                    <td className="woocommerce-product-attributes-item__value">0.3 kg</td>
                                                </tr>
                                                <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--dimensions">
                                                    <th className="woocommerce-product-attributes-item__label">Dimensions</th>
                                                    <td className="woocommerce-product-attributes-item__value">15 &times; 17 &times; 4 cm</td>
                                                </tr>
                                                <tr className="woocommerce-product-attributes-item woocommerce-product-attributes-item--attribute_pa_colors">
                                                    <th className="woocommerce-product-attributes-item__label">Colors</th>
                                                    <td className="woocommerce-product-attributes-item__value">
                                                    <p>Gold, Pink Gold, Silver</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="woocommerce-Tabs-panel woocommerce-Tabs-panel--reviews panel entry-content wc-tab" id="tab-reviews" role="tabpanel" aria-labelledby="tab-title-reviews">
                                        <div id="reviews" className="woocommerce-Reviews">
                                            <div id="comments">
                                                <h2 className="woocommerce-Reviews-title">Reviews </h2>
                                                <p className="woocommerce-noreviews">There are no reviews yet.</p>
                                            </div>
                                            <div id="review_form_wrapper">
                                                <div id="review_form">
                                                    <div id="respond" className="comment-respond">
                                                        <h3 id="reply-title" className="comment-reply-title">Be the first to review {name}<small><a rel="nofollow" id="cancel-comment-reply-link" href="#" style={{display:"none"}}>Cancel reply</a></small></h3>
                                                        <form action="https://ascella.qodeinteractive.com/wp-comments-post.php" method="post" id="commentform" className="qodef-comment-form">
                                                                <p className="comment-notes">
                                                                <span id="email-notes">Your email address will not be published.</span> 
                                                                <span className="required-field-message">Required fields are marked <span className="required">*</span></span></p>
                                                                <div className="comment-form-rating">
                                                                    <label htmlFor="rating">Your Rating <span className="required">*</span></label>
                                                                    <p className="stars qodef-comment-form-ratings">
                                                                            <a className="star-1" href="#">1 
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="qodef-svg--star" viewBox="0 0 32 32">
                                                                                    <path d="M20.756 11.768l-4.9-9.928-4.9 9.928L0 13.36l7.928 7.728L6.056 32l9.8-5.152 9.8 5.152-1.872-10.912 7.928-7.728z"></path>
                                                                                </svg>
                                                                            </a>
                                                                            <a className="star-2" href="#">2  
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="qodef-svg--star" viewBox="0 0 32 32"> <path d="M20.756 11.768l-4.9-9.928-4.9 9.928L0 13.36l7.928 7.728L6.056 32l9.8-5.152 9.8 5.152-1.872-10.912 7.928-7.728z"></path></svg>
                                                                            </a>
                                                                            <a className="star-3" href="#">3
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="qodef-svg--star" viewBox="0 0 32 32"> <path d="M20.756 11.768l-4.9-9.928-4.9 9.928L0 13.36l7.928 7.728L6.056 32l9.8-5.152 9.8 5.152-1.872-10.912 7.928-7.728z"></path></svg>
                                                                            </a>
                                                                            <a className="star-4" href="#">4
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="qodef-svg--star" viewBox="0 0 32 32">
                                                                                    <path d="M20.756 11.768l-4.9-9.928-4.9 9.928L0 13.36l7.928 7.728L6.056 32l9.8-5.152 9.8 5.152-1.872-10.912 7.928-7.728z"></path>
                                                                                </svg>
                                                                            </a>
                                                                            <a className="star-5" href="#">5
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="qodef-svg--star" viewBox="0 0 32 32"> 
                                                                                    <path d="M20.756 11.768l-4.9-9.928-4.9 9.928L0 13.36l7.928 7.728L6.056 32l9.8-5.152 9.8 5.152-1.872-10.912 7.928-7.728z"></path>
                                                                                </svg>
                                                                            </a>
                                                                    </p>
                                                                    <select name="rating" id="rating" required>
                                                                        <option value="">Rate&hellip;</option>
                                                                        <option value="5">Perfect</option>
                                                                        <option value="4">Good</option>
                                                                        <option value="3">Average</option>
                                                                        <option value="2">Not that bad</option>
                                                                        <option value="1">Very poor</option>
                                                                    </select>
                                                                </div>
                                                                <p className="comment-form-comment">
                                                                    <textarea id="comment" name="comment" placeholder="Your Review *" cols="45" rows="8" maxLength="65525" required="required"></textarea>
                                                                </p>
                                                                <p className="comment-form-author">
                                                                    <input id="author" name="author" placeholder="Your Name *" type="text" size="30" maxLength="245" required="required" />
                                                                </p>
                                                                <p className="comment-form-email">
                                                                    <input id="email" name="email" placeholder="Your Email *" type="text" size="30" maxLength="100" aria-describedby="email-notes" required="required" />
                                                                </p>
                                                                <p className="comment-form-cookies-consent">
                                                                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" /> 
                                                                    <label htmlFor="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                                                </p>
                                                                <p className="form-submit">
                                                                    <button name="submit" type="submit" id="submit" className="qodef-button qodef-layout--outlined" value="Submit">
                                                                    <span className="qodef-m-text">Submit</span></button>  
                                                                </p>
                                                                <div className="wantispam-required-fields">
                                                                    <input type="hidden" name="wantispam_t" className="wantispam-control wantispam-control-t" />
                                                                    <div className="wantispam-group wantispam-group-q" style={{clear: "both"}}>
                                                                        <label>Current <a href="#" className="__cf_email__">email@gmail.com</a> <span className="required">*</span></label>
                                                                        <input type="hidden" name="wantispam_a" className="wantispam-control wantispam-control-a" />
                                                                        <input type="text" name="wantispam_q" className="wantispam-control wantispam-control-q" autoComplete="off" />
                                                                    </div>
                                                                    <div className="wantispam-group wantispam-group-e" style={{display: "none"}}>
                                                                        <label>Leave this field empty</label>
                                                                        <input type="text" name="wantispam_e_email_url_website" className="wantispam-control wantispam-control-e"  autoComplete="off" />
                                                                    </div>
                                                                </div>
                                                        </form> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className="related products">
                                        <h2>Related products</h2>
                                        <div className="qodef-woo-product-list qodef-item-layout--info-below">
                                            <ul className="products columns-2">
                                                <li className="product type-product post-893 status-publish first instock product_cat-beauty-bracelests product_cat-jewelry product_tag-gold product_tag-jewerly has-post-thumbnail shipping-taxable purchasable product-type-variable">
                                                    <div className="qodef-e-inner">
                                                        <div className="qodef-e-media">
                                                            <div className="qodef-e-media-image">
                                                                <a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link">
                                                                    <img width="480" height="643" src="../../wp-content/uploads/2022/12/h3-shop-pr-1-img-1-480x643.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-1-img-1-480x643.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-1-img-1-224x300.jpg 224w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-1-img-1-764x1024.jpg 764w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-1-img-1-768x1029.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-1-img-1-400x536.jpg 400w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-1-img-1.jpg 800w" sizes="(max-width: 480px) 100vw, 480px" />
                                                                </a>
                                                            </div>
                                                            <div className="qodef-e-media-inner">
                                                                <a href="#" data-quantity="1" className="button wp-element-button product_type_variable add_to_cart_button" data-product_id="893" data-product_sku="029" aria-label="Select options for &ldquo;Mix Necklaces&rdquo;" rel="nofollow">Select options</a>
                                                            </div>
                                                            <a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"></a>
                                                        </div>
                                                        <div className="qodef-e-content">
                                                            <div className="qodef-e-top-holder">
                                                                <h6 className="qodef-woo-product-title woocommerce-loop-product__title">
                                                                    <a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link">Mix Necklaces</a>
                                                                </h6>
                                                                <div className="qodef-additional-holder">
                                                                    <div className="yith-wcwl-add-to-wishlist add-to-wishlist-893  wishlist-fragment on-first-load" data-fragment-ref="893" data-fragment-options="{&quot;base_url&quot;:&quot;&quot;,&quot;in_default_wishlist&quot;:false,&quot;is_single&quot;:false,&quot;show_exists&quot;:false,&quot;product_id&quot;:893,&quot;parent_product_id&quot;:893,&quot;product_type&quot;:&quot;variable&quot;,&quot;show_view&quot;:false,&quot;browse_wishlist_text&quot;:&quot;Browse wishlist&quot;,&quot;already_in_wishslist_text&quot;:&quot;The product is already in your wishlist!&quot;,&quot;product_added_text&quot;:&quot;Product added!&quot;,&quot;heading_icon&quot;:&quot;fa-heart-o&quot;,&quot;available_multi_wishlist&quot;:false,&quot;disable_wishlist&quot;:false,&quot;show_count&quot;:false,&quot;ajax_loading&quot;:false,&quot;loop_position&quot;:&quot;after_add_to_cart&quot;,&quot;item&quot;:&quot;add_to_wishlist&quot;}">
                                                                        <div className="yith-wcwl-add-button">
                                                                            <a href="#" className="add_to_wishlist single_add_to_wishlist" data-product-id="893" data-product-type="variable" data-original-product-id="893" data-title="Add to wishlist" rel="nofollow">
                                                                                <i className="yith-wcwl-icon fa fa-heart-o"></i> <span>Add to wishlist</span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <a href="#" className="button yith-wcqv-button" data-product_id="893">Quick View</a>
                                                                </div>
                                                            </div>
                                                            <div className="qodef-e-info"> <a href="#" rel="tag">Beauty bracelests</a><span className="qodef-info-separator-single"></span><a href="#" rel="tag">Jewelry</a> 
                                                                <div className="qodef-info-separator-end"></div>
                                                            </div>
                                                            <span className="price"><span className="woocs_price_code" data-currency="" data-redraw-id="647832b5843f0" data-product-id="893"><span className="woocommerce-Price-amount amount"><bdi>82.00<span className="woocommerce-Price-currencySymbol">&#36;</span></bdi></span></span></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="product type-product post-1022 status-publish last instock product_cat-jewelry product_tag-gold product_tag-jewerly has-post-thumbnail shipping-taxable purchasable product-type-simple">
                                                    <div className="qodef-e-inner">
                                                        <div className="qodef-e-media">
                                                            <div className="qodef-e-media-image">
                                                                <a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link">
                                                                    <img width="480" height="643" src="../../wp-content/uploads/2022/12/h3-shop-pr-8-img-1-480x643.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="d" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-8-img-1-480x643.jpg 480w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-8-img-1-224x300.jpg 224w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-8-img-1-764x1024.jpg 764w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-8-img-1-768x1029.jpg 768w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-8-img-1-400x536.jpg 400w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h3-shop-pr-8-img-1.jpg 800w" sizes="(max-width: 480px) 100vw, 480px" />
                                                                </a>
                                                            </div>
                                                            <div className="qodef-e-media-inner">
                                                                <a href="#" data-quantity="1" className="button wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id="1022" data-product_sku="053" aria-label="Add &ldquo;Elegant Earrings&rdquo; to your cart" rel="nofollow">Add to cart</a>
                                                            </div>
                                                            <a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link"></a>
                                                        </div>
                                                        <div className="qodef-e-content">
                                                            <div className="qodef-e-top-holder">
                                                                <h6 className="qodef-woo-product-title woocommerce-loop-product__title">
                                                                    <a href="#" className="woocommerce-LoopProduct-link woocommerce-loop-product__link">Elegant Earrings</a>
                                                                </h6>
                                                                <div className="qodef-additional-holder">
                                                                    <div className="yith-wcwl-add-to-wishlist add-to-wishlist-1022  wishlist-fragment on-first-load" data-fragment-ref="1022" data-fragment-options="{&quot;base_url&quot;:&quot;&quot;,&quot;in_default_wishlist&quot;:false,&quot;is_single&quot;:false,&quot;show_exists&quot;:false,&quot;product_id&quot;:1022,&quot;parent_product_id&quot;:1022,&quot;product_type&quot;:&quot;simple&quot;,&quot;show_view&quot;:false,&quot;browse_wishlist_text&quot;:&quot;Browse wishlist&quot;,&quot;already_in_wishslist_text&quot;:&quot;The product is already in your wishlist!&quot;,&quot;product_added_text&quot;:&quot;Product added!&quot;,&quot;heading_icon&quot;:&quot;fa-heart-o&quot;,&quot;available_multi_wishlist&quot;:false,&quot;disable_wishlist&quot;:false,&quot;show_count&quot;:false,&quot;ajax_loading&quot;:false,&quot;loop_position&quot;:&quot;after_add_to_cart&quot;,&quot;item&quot;:&quot;add_to_wishlist&quot;}">
                                                                        <div className="yith-wcwl-add-button">
                                                                            <a href="#" className="add_to_wishlist single_add_to_wishlist" data-product-id="1022" data-product-type="simple" data-original-product-id="1022" data-title="Add to wishlist" rel="nofollow">
                                                                                <i className="yith-wcwl-icon fa fa-heart-o"></i> <span>Add to wishlist</span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <a href="#" className="button yith-wcqv-button" data-product_id="1022">Quick View</a>
                                                                </div>
                                                            </div>
                                                            <div className="qodef-e-info"> 
                                                                <a href="#" rel="tag">Jewelry</a> 
                                                                <div className="qodef-info-separator-end"></div>
                                                            </div>
                                                            <span className="price">
                                                                <span className="woocs_price_code" data-currency="" data-redraw-id="647832b5862b5" data-product-id="1022"><span className="woocommerce-Price-amount amount"><bdi>85.00<span className="woocommerce-Price-currencySymbol">&#36;</span></bdi></span></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                            </section>
                        </div>
                    </div> 
                </div>
            </main>
        </div>
    </div>
  )
}
export default ProductDetail;
