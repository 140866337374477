import React from 'react';
import client1 from '../assets/img/uploads/2022/12/h2-client-img-1.png';
import client2 from '../assets/img/uploads/2022/12/h2-client-img-2.png';
import client3 from '../assets/img/uploads/2022/12/h2-client-img-3.png';
import client4 from '../assets/img/uploads/2022/12/h2-client-img-4.png';
import smallbanner2 from '../assets/img/uploads/2023/03/h2-img-4-ver-2.jpg';

const ClientInfo = () => {
  return (
    <div>
        <section className="elementor-section elementor-top-section elementor-element elementor-element-fbf4ae5 elementor-section-full_width elementor-reverse-tablet elementor-reverse-mobile_extra elementor-reverse-mobile elementor-section-height-default elementor-section-height-default" data-id="fbf4ae5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-fe9b783" data-id="fe9b783" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-e033fde elementor-widget__width-initial elementor-widget elementor-widget-heading" data-id="e033fde" data-element_type="widget" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<h2 className="elementor-heading-title elementor-size-default">& Beauty</h2> 
							</div>
						</div>
						<div className="elementor-element elementor-element-db5fb18 elementor-widget elementor-widget-spacer" data-id="db5fb18" data-element_type="widget" data-widget_type="spacer.default">
							<div className="elementor-widget-container">
							<div className="elementor-spacer">
							<div className="elementor-spacer-inner"></div>
							</div>
							</div>
						</div>
						<div className="elementor-element elementor-element-5c4e373 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-ascella_core_info_section" data-id="5c4e373" data-element_type="widget" data-widget_type="ascella_core_info_section.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-info-section qodef-layout--background-text  qodef-background-text-pos--top-left">
									<div className="qodef-m-background-text"></div>
									<div className="qodef-m-info">
										<h4 className="qodef-m-title">inspired by love &amp; nature	</h4>
										<div className="qodef-m-text">
											<p>Risus in hendrerit gravida rutrum quisque non tellus. Dapibus ultrices in iaculis nunc. Nulla pharetra diam sit amet nisl suscipit adipiscing bibendum est. Turpis massa sed elementum tempus.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="elementor-element elementor-element-f2f202a elementor-widget elementor-widget-spacer" data-id="f2f202a" data-element_type="widget" data-widget_type="spacer.default">
							<div className="elementor-widget-container">
								<div className="elementor-spacer">
									<div className="elementor-spacer-inner"></div>
								</div>
							</div>
						</div>
						<div className="elementor-element elementor-element-b1d87b0 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-ascella_core_clients_list" data-id="b1d87b0" data-element_type="widget" data-widget_type="ascella_core_clients_list.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-clients-list qodef-grid qodef-layout--columns  qodef-gutter--no  qodef-col-num--4 qodef-item-layout--image-only qodef-responsive--custom qodef-col-num--1440--4 qodef-col-num--1366--4 qodef-col-num--1024--4 qodef-col-num--768--4 qodef-col-num--680--1 qodef-col-num--480--1 qodef-hover-animation--no-hover">
									<div className="qodef-grid-inner">
										<span className="qodef-e qodef-grid-item ">
											<span className="qodef-e-inner">
												<span className="qodef-e-image">
													<a itemProp="url" href="#" target="_self">
														<span className="qodef-e-logo">
															<img width="106" height="40" src={client1} className="attachment-full size-full" alt="v" decoding="async" loading="lazy" /> 
														</span>
														<span className="qodef-e-hover-logo">
															<img width="106" height="40" src={client1} className="attachment-full size-full" alt="v" decoding="async" loading="lazy" /> 
														</span>
													</a>
												</span>
											</span>
										</span>
										<span className="qodef-e qodef-grid-item ">
											<span className="qodef-e-inner">
												<span className="qodef-e-image">
													<a itemProp="url" href="#" target="_blank">
														<span className="qodef-e-logo">
															<img width="106" height="40" src={client2} className="attachment-full size-full" alt="v" decoding="async" loading="lazy" /> 
														</span>
														<span className="qodef-e-hover-logo">
															<img width="106" height="40" src={client2} className="attachment-full size-full" alt="v" decoding="async" loading="lazy" /> 
														</span>
													</a>
												</span>
											</span>
										</span>
										<span className="qodef-e qodef-grid-item ">
											<span className="qodef-e-inner">
												<span className="qodef-e-image">
													<a itemProp="url" href="#" target="_blank">
														<span className="qodef-e-logo">
															<img width="106" height="40" src={client3} className="attachment-full size-full" alt="v" decoding="async" loading="lazy" /> 
														</span>
														<span className="qodef-e-hover-logo">
															<img width="106" height="40" src={client3} className="attachment-full size-full" alt="v" decoding="async" loading="lazy" /> 
														</span>
													</a>
												</span>
											</span>
										</span>
										<span className="qodef-e qodef-grid-item ">
											<span className="qodef-e-inner">
												<span className="qodef-e-image">
													<a itemProp="url" href="#" target="_blank">
														<span className="qodef-e-logo">
															<img width="106" height="40" src={client4} className="attachment-full size-full" alt="v" decoding="async" loading="lazy" /> 
														</span>
														<span className="qodef-e-hover-logo">
															<img width="106" height="40" src={client4} className="attachment-full size-full" alt="v" decoding="async" loading="lazy" /> 
														</span>
													</a>
												</span>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e04e114" data-id="e04e114" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-085bc1a elementor-widget__width-initial elementor-widget-laptop__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-ascella_core_single_image" data-id="085bc1a" data-element_type="widget" data-widget_type="ascella_core_single_image.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-single-image qodef-layout--default   qodef--has-decoration qodef--custom-appear ">
									<div className="qodef-m-image">
										<img width="1000" height="822" src={smallbanner2} className="attachment-full size-full" alt="d" decoding="async" loading="lazy" srcSet={smallbanner2} sizes="(max-width: 1000px) 100vw, 1000px" /> 
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default ClientInfo;