import React from 'react';
import testimonials from '../assets/img/uploads/2022/12/h2-testimonials-img-1.png';

const Testimonials = () => {
  return (
    <div>
      <section className="elementor-section elementor-top-section elementor-element elementor-element-5ae0a98 elementor-section-full_width elementor-section-height-min-height elementor-section-height-default elementor-section-items-middle" data-id="5ae0a98" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
		<div className="elementor-container elementor-column-gap-default">
			<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-52ff17b" data-id="52ff17b" data-element_type="column">
				<div className="elementor-widget-wrap elementor-element-populated">
					<div className="elementor-element elementor-element-835d4f0 elementor-widget elementor-widget-ascella_core_testimonials_list" data-id="835d4f0" data-element_type="widget" data-widget_type="ascella_core_testimonials_list.default">
						<div className="elementor-widget-container">
							<div className="qodef-testimonials-wrapper">
								<div className="qodef-shortcode qodef-m  qodef-testimonials-list qodef-skin--light qodef-content-align--center qodef-content-grid qodef-grid qodef-swiper-container    qodef-col-num--1 qodef-item-layout--info-below qodef-responsive--predefined" data-options="{&quot;slidesPerView&quot;:&quot;1&quot;,&quot;spaceBetween&quot;:0,&quot;loop&quot;:true,&quot;autoPlay&quot;:true,&quot;speed&quot;:&quot;&quot;,&quot;speedAnimation&quot;:&quot;&quot;,&quot;slideAnimation&quot;:&quot;&quot;,&quot;slideDecimal&quot;:&quot;&quot;,&quot;direction&quot;:&quot;&quot;,&quot;centeredSlides&quot;:&quot;&quot;,&quot;sliderScroll&quot;:&quot;&quot;,&quot;loopAdditionalSlides&quot;:&quot;&quot;,&quot;outsideNavigation&quot;:&quot;yes&quot;,&quot;unique&quot;:409}">
									<div className="swiper-wrapper">
										<div className="qodef-e swiper-slide ">
											<div className="qodef-e-inner">
												<div className="qodef-e-media-image">
													<img loading="lazy" width="105" height="18" src={testimonials} className="attachment-full size-full wp-post-image" alt="d" decoding="async" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-testimonials-img-1.png 105w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-testimonials-img-1-100x18.png 100w" sizes="(max-width: 105px) 100vw, 105px" /> 
												</div>
												<div className="qodef-e-content">
													<h4 itemProp="name" className="qodef-e-title entry-title" style={{texttransform: "none"}}>
													&quot;Nulla pellentesque dignissim enim sit amet venenatis urna. Congue quisque egestas diam in arcu cursus euismod quis viverra. Sit ameuksnt aliqu.&quot;	</h4>
													<span className="qodef-e-author">Simon Fray	</span>
													<span className="qodef-e-location">London, UK	</span>
													<span className="qodef-e-date">2 months ago	</span>
												</div>
											</div>
										</div>
										<div className="qodef-e swiper-slide ">
											<div className="qodef-e-inner">
												<div className="qodef-e-media-image">
													<img width="105" height="18" src={testimonials} className="attachment-full size-full wp-post-image" alt="d" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-testimonials-img-1.png 105w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-testimonials-img-1-100x18.png 100w" sizes="(max-width: 105px) 100vw, 105px" /> 
												</div>
												<div className="qodef-e-content">
													<h4 itemProp="name" className="qodef-e-title entry-title" style={{texttransform: "none"}}>
													&quot;Nec nam aliquam sem et tortor consequat. Lacus sed turpis tincidunt id aliquet risus feugiat. Sagittis eu volutpat odio facilisis mauris sit amet.&quot;	</h4>
													<span className="qodef-e-author">Dean Lowey	</span>
													<span className="qodef-e-location">New York, USA	</span>
													<span className="qodef-e-date">2 months ago	</span>
												</div>
											</div>
										</div>
										<div className="qodef-e swiper-slide ">
											<div className="qodef-e-inner">
												<div className="qodef-e-media-image">
													<img width="105" height="18" src={testimonials} className="attachment-full size-full wp-post-image" alt="d" decoding="async" loading="lazy" srcSet="https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-testimonials-img-1.png 105w, https://ascella.qodeinteractive.com/wp-content/uploads/2022/12/h2-testimonials-img-1-100x18.png 100w" sizes="(max-width: 105px) 100vw, 105px" /> 
												</div>
												<div className="qodef-e-content">
													<h4 itemProp="name" className="qodef-e-title entry-title" style={{texttransform: "none"}}>
													&quot;Cursus euismod quis viverra nibh cras pulvinar mattis nunc. Adipiscing at in tellus integer. Varius duis at consectetur lorem donec massa sapien.&quot;	</h4>
													<span className="qodef-e-author">Matt Smith	</span>
													<span className="qodef-e-location">Oslo, Norway	</span>
													<span className="qodef-e-date">6 months ago	</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="swiper-button-prev swiper-button-outside swiper-button-prev-409">
									<svg className="qodef-svg--slider-arrow-left" width="24" height="50" x="0" y="0" viewBox="0 0 24 50">
										<path d="M22.7.3L.7 24.8l22 24.5"></path>
									</svg>
								</div>
								<div className="swiper-button-next swiper-button-outside swiper-button-next-409"> 
									<svg className="qodef-svg--slider-arrow-right" width="24" height="50" x="0" y="0" viewBox="0 0 24 50">
										<path d="M.4.3l22 24.5-22 24.5"></path>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	  </section>
    </div>
  )
}

export default Testimonials
