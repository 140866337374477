import React from 'react';
import payment from '../assets/img/uploads/2023/03/h2-icon-1-x2.png';
import shipping from '../assets/img/uploads/2023/03/h2-icon-2-x2.png';
import returns from '../assets/img/uploads/2023/03/h2-icon-3-x2.png';
import quality from '../assets/img/uploads/2023/03/h2-icon-4-x2.png';

const Features = () => {
  return (
    <div>
        <section className="elementor-section elementor-top-section elementor-element elementor-element-0be59b4 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="0be59b4" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-9420f62" data-id="9420f62" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-fdb372d elementor-widget elementor-widget-ascella_core_icon_with_text" data-id="fdb372d" data-element_type="widget" data-widget_type="ascella_core_icon_with_text.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-icon-with-text qodef-layout--top qodef--custom-icon qodef--retina qodef-alignment--center">
									<div className="qodef-m-icon-wrapper">
										<a itemProp="url" href="#" target="_self">
											<img width="83" height="53" src={payment} className="attachment-full size-full" alt="y" decoding="async" loading="lazy" /> 
										</a>
									</div>
									<div className="qodef-m-content">
										<h6 className="qodef-m-title" style={{marginTop: "16px"}}>
										<a itemProp="url" href="#" target="_self">
											<span className="qodef-m-title-text">Payment</span>
										</a>
										</h6>
										<p className="qodef-m-text" style={{margintop: "1px"}}>Conse quat interdum</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-3119bc8" data-id="3119bc8" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-0388d83 elementor-widget elementor-widget-ascella_core_icon_with_text" data-id="0388d83" data-element_type="widget" data-widget_type="ascella_core_icon_with_text.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-icon-with-text qodef-layout--top qodef--custom-icon qodef--retina qodef-alignment--center">
									<div className="qodef-m-icon-wrapper">
										<a itemProp="url" href="#" target="_self">
										<img width="95" height="55" src={shipping} className="attachment-full size-full" alt="p" decoding="async" loading="lazy" /> </a>
									</div>
									<div className="qodef-m-content">
										<h6 className="qodef-m-title" style={{margintop: "16px"}}>
											<a itemProp="url" href="#" target="_self">
												<span className="qodef-m-title-text">shipping &amp; delivery</span>
											</a>
										</h6>
										<p className="qodef-m-text" style={{margintop: "1px"}}>Aliquet eget sit</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-ab417c5" data-id="ab417c5" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-5c31fae elementor-widget elementor-widget-ascella_core_icon_with_text" data-id="5c31fae" data-element_type="widget" data-widget_type="ascella_core_icon_with_text.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-icon-with-text qodef-layout--top qodef--custom-icon qodef--retina qodef-alignment--center">
									<div className="qodef-m-icon-wrapper">
									<a itemProp="url" href="#" target="_self">
									<img width="73" height="67" src={returns} className="attachment-full size-full" alt="p" decoding="async" loading="lazy" /> </a>
									</div>
									<div className="qodef-m-content">
										<h6 className="qodef-m-title" style={{margintop: "13px"}}>
											<a itemProp="url" href="#" target="_self">
												<span className="qodef-m-title-text">returns &amp; exchanges</span>
											</a>
										</h6>
										<p className="qodef-m-text" style={{margintop: "1px"}}>Sagits aliquam</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-5ffb00d" data-id="5ffb00d" data-element_type="column">
					<div className="elementor-widget-wrap elementor-element-populated">
						<div className="elementor-element elementor-element-6273296 elementor-widget elementor-widget-ascella_core_icon_with_text" data-id="6273296" data-element_type="widget" data-widget_type="ascella_core_icon_with_text.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m  qodef-icon-with-text qodef-layout--top qodef--custom-icon qodef--retina qodef-alignment--center">
									<div className="qodef-m-icon-wrapper">
										<a itemProp="url" href="#" target="_self">
										<img width="55" height="63" src={quality} className="attachment-full size-full" alt="p" decoding="async" loading="lazy" /> </a>
									</div>
									<div className="qodef-m-content">
										<h6 className="qodef-m-title" style={{margintop: "14px"}}>
											<a itemProp="url" href="#" target="_self">
												<span className="qodef-m-title-text">High quality</span>
											</a>
										</h6>
										<p className="qodef-m-text" style={{margintop: "1px"}}>Aliqua quat interdum</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default Features;
