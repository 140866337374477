import React from 'react';
import Slider from './component/Slider';
import Collection from './component/Collection';
import Introduction from './component/Introduction';
import Products from './component/FeaturedProducts';
import ClientInfo from './component/ClientInfo';
import Video from './component/Video';
import Features from './component/Features';
import Categories from './component/Categories';
import Blog from './component/Blog';
import Testimonials from './component/Testimonials';

const Home = () => {
  return (
    <div id="qodef-page-outer">
		<div id="qodef-page-inner" className="qodef-content-full-width">
			<main id="qodef-page-content" className="qodef-grid qodef-layout--template qodef-grid-template--12 " role="main">
				<div className="qodef-grid-inner">
					<div className="qodef-grid-item qodef-page-content-section qodef-col--content">
						<div data-elementor-type="wp-page" data-elementor-id="336" className="elementor elementor-336">
							<Slider />
							<Collection />
							<Introduction />
							<Products />
							<ClientInfo />
							<Video />
							<Features />
							<Categories />
							<Testimonials />
							<Blog />
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>	
  )
}

export default Home;